import { useEmployeeStore } from "@/store/pinia/employee";
import { computed } from "vue";
import { updateEmployeeReadiness, updateAllEmployeeReadiness } from "@/api";

export function useReadinessEmployees() {
  const employeeStore = useEmployeeStore();

  const allEmployees = computed(() =>
    employeeStore.employees.filter(
      (employee) => employee.current_status === "active"
    )
  );
  const getEmployeeReadinessRecal = async (accountID, item) => {
    await getEmployeeReadiness(accountID, item);
  };

  const loadAllEmployees = async () => {
    await employeeStore.loadEmployees();
  };
  const getEmployeeReadiness = async (accountID, item) => {
    try {
      if (item && item?.id) {
        await updateEmployeeReadiness(accountID, item.id);
        await employeeStore.loadEmployees();
      }
    } catch (e) {
      console.log("e: ", e);
    }
  };
  const recalculateEmployees = async () => {
    const employees_ids = employeeStore.employees
      .filter((employee) => employee.needs_recalc === true) // Filtering employees where needs_recalc is true
      .map((employee) => employee.id);
    const obj = {
      employees_ids: employees_ids,
    };
    const accountID = localStorage.getItem("currentAccountID");
    try {
      await updateAllEmployeeReadiness(accountID, obj);
      await employeeStore.loadEmployees();
    } catch (e) {
      console.log("e: ", e);
    }
  };
  return {
    allEmployees,
    loadAllEmployees,
    getEmployeeReadinessRecal,
    getEmployeeReadiness,
    recalculateEmployees,
  };
}

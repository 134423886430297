<template>
  <v-container fluid>
    <UniversalTable
      :config="tableConfig.tableParams.value"
      :items="filteredPositions"
      @onActionClick="viewState.state.addPositionModal.show = true"
      @onSearch="viewState.state.filters.searchText = $event"
      @onClick="viewState.state.filters.showFilterDialog = true"
      @removeFilter="viewState.removeFilters"
      @removeAllFilters="viewState.state.filters.values = []"
      :emptyObjectData="{ singular: 'Position', plural: 'Positions' }"
    >
      <template #Dropdown-activator>
        <div class="icon-container">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-svg"
          >
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </template>
    </UniversalTable>

    <AddOrgObject
      :show="viewState.state.addPositionModal.show"
      @onAdd="
        viewState.handlePositionOperation(
          positions.operations.CREATE_POSITION,
          accountID,
          $event
        )
      "
      OrgObjectType="Position"
      ghostText="+ Add"
      description="You are about to add a new"
      type="add"
      :button-label="proxy.$loader.isAppLoading ? 'Saving' : 'Save'"
      @close="viewState.state.addPositionModal.show = false"
    />

    <ExOrgObjectActions
      v-model="viewState.state.activatePositionModal.show"
      objectType="Position"
      :name="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.ACTIVATE_POSITION,
          accountID
        )
      "
    />

    <ExDeleteOrgObject
      v-model="viewState.state.deletePositionModal.show"
      OrgObjectType="Position"
      :name="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.DELETE_POSITION,
          accountID
        )
      "
    />

    <RenameOrgObject
      v-if="viewState.state.renamePositionModal.show"
      :openDialog="viewState.state.renamePositionModal.show"
      :name="positions.getCurrentPosition()?.name || ''"
      OrgObjectType="Position"
      :placeholder="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.RENAME_POSITION,
          accountID,
          $event
        )
      "
      @onClose="viewState.state.renamePositionModal.show = false"
    />

    <ArchivePopUp
      :action="viewState.state.archiveAction"
      type="position"
      :show="viewState.state.archivePositionModal.show"
      :curretObj="positions.getCurrentPosition()"
      @onClose="viewState.state.archivePositionModal.show = false"
      @onArchive="
        viewState.handlePositionOperation(
          positions.operations.ARCHIVE_POSITION,
          accountID
        )
      "
    />

    <AttachedRole
      v-model="viewState.state.attachRoleModal.show"
      OrgObjectType="Role to Position"
      @onSave="viewState.attachRole(accountID)"
      :items="viewState.state.attachRoleModal.roles"
      @item-selected="viewState.handleRoleSelected"
      @add-item-selected="viewState.handleAddRoleSelected"
      :parent-name="positions.getCurrentPosition()?.name || ''"
      :existingNamesList="viewState.state.attachRoleModal.attachedRoles"
    />

    <SearchModal
      :dialog="viewState.state.filters.showFilterDialog"
      @back="viewState.state.filters.showFilterDialog = false"
      @checkedItemsChanged="viewState.applyFilters"
      :clearSelection="viewState.state.filters.clearSelection"
      filterType="Positions"
      :filterGroups="viewState.state.filters.filterGroups"
      :statusOptions="viewState.state.filters.statusOptions"
      :modalWidth="620"
      :defaultSelected="viewState.state.filters.values"
    />

    <RelievePrimaryAssignment
      v-if="viewState.state.relieveAssignment.show"
      :show="viewState.state.relieveAssignment.show"
      :avatar="viewState.getRelieveAssignmentAvatar()"
      :current-position="positions.getCurrentPosition()?.name || ''"
      @onClose="viewState.closeRelieveAssignment()"
      @onAction="viewState.relievePrimaryAssignment(accountID)"
    />

    <RetirePositionPopup
      v-model="viewState.state.retirePositionModal.show"
      OrgObjectType="Position"
      :name="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.RETIRE_POSITION,
          accountID
        )
      "
    />
    <ReActivatePosition
      v-model="viewState.state.reactivatePositionModal.show"
      OrgObjectType="Position"
      :name="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.REACTIVATE_POSITION,
          accountID
        )
      "
    />
    <ExDeActivatePopup
      v-model="viewState.state.deactivatePositionModal.show"
      OrgObjectType="Position"
      :name="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.DEACTIVATE_POSITION,
          accountID
        )
      "
    />

    <ExCloneOrgObject
      v-model="viewState.state.clonePositionModal.show"
      OrgObjectType="Position"
      :name="positions.getCurrentPosition()?.name || ''"
      @onAction="
        viewState.handlePositionOperation(
          positions.operations.CLONE_POSITION,
          accountID
        )
      "
    />
  </v-container>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, computed } from "vue";
import { usePositions } from "@/composables/positions/usePositions";
import { useRoles } from "@/composables/catalogs/useRoles";
import { useEmployees } from "@/composables/employees/useEmployees";
import { useTableConfig } from "@/views/Positions/useTableConfig";
import { usePositionViewState } from "@/views/Positions/usePositionViewState";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import AddOrgObject from "@components/AddOrgObject/AddOrgObject.vue";
import ExOrgObjectActions from "@components/common/ExOrgObjectActions.vue";
import ExDeleteOrgObject from "@components/DeleteOrgObject/ExDeleteOrgObject.vue";
import RenameOrgObject from "@components/RenameOrgObject/RenameOrgObject.vue";
import RetirePositionPopup from "@components/RetirePositionPopup/RetirePositionPopup.vue";
import ArchivePopUp from "@components/common/ArchivePopUp.vue";
import AttachedRole from "@components/RolesCatalog/AttachedRole.vue";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import RelievePrimaryAssignment from "@components/organizations/employees/RelievePrimaryAssignment.vue";
import ReActivatePosition from "@components/ReActivatePosition/ReActivatePositionPopup.vue";
import ExDeActivatePopup from "@components/DeActivatePosition/DeActivatePositionPopup.vue";
import ExCloneOrgObject from "@components/CloneOrgObject/ExCloneOrgObject.vue";

const app = getCurrentInstance();
const { proxy } = app;

const positions = usePositions();
const employees = useEmployees();
const tableConfig = useTableConfig();
const viewState = usePositionViewState();
const roles = useRoles();

const accountID = ref(JSON.parse(localStorage.getItem("currentAccountID")));

const filteredPositions = computed(() => {
  let allPositions = positions.allPositions.value;

  let sanitizedPositions = allPositions.map((el) => {
    const totalCount = Object.values(el.attachments_count).reduce(
      (sum, count) => sum + count,
      0
    );
    el.totalAssignment = totalCount;
    return el;
  });

  if (viewState.state.filters.searchText?.trim()) {
    const searchText = viewState.state.filters.searchText.toLowerCase();
    sanitizedPositions = sanitizedPositions.filter((position) => {
      return (
        position.name.toLowerCase().includes(searchText) ||
        position.code_str?.toString().includes(searchText)
      );
    });
  }

  if (
    viewState.state.filters.values.some((val) =>
      viewState.state.filters.statusOptions.includes(val)
    )
  ) {
    if (viewState.state.filters.values.includes("Show")) {
      sanitizedPositions = sanitizedPositions.filter((position) =>
        viewState.state.filters.values.includes(position.status)
      );
    } else {
      sanitizedPositions = sanitizedPositions.filter(
        (position) =>
          viewState.state.filters.values.includes(position.status) &&
          !position.archived
      );
    }
  }

  const archivedFilters = ["Show", "Do not Show"];
  if (
    viewState.state.filters.values.some((val) => archivedFilters.includes(val))
  ) {
    sanitizedPositions = sanitizedPositions.filter((position) => {
      return viewState.state.filters.values.some((filter) => {
        if (filter === "Show") {
          return true;
        } else if (filter === "Do not Show") {
          return !position.archived;
        }
        return false;
      });
    });
  }

  if (viewState.state.filters.values.length === 0) {
    sanitizedPositions = sanitizedPositions.filter(
      (position) => !position.archived
    );
  }

  return sanitizedPositions.sort((a, b) => a.name.localeCompare(b.name));
});

onMounted(async () => {
  proxy.$loader.show();
  await positions.loadAllPositions();
  await roles.loadAllRoles();
  await employees.loadAllEmployees();
  proxy.$loader.hide();
  // Rest filters on navigation from another components
  if (
    sessionStorage.getItem("navigated") !== null &&
    sessionStorage.getItem("navigated") == "Login"
  ) {
    viewState.state.filters.values = [];
  }
});
</script>

<style>
.v-data-table__wrapper .datatable-text-content a {
  text-decoration: none !important;
}
.v-data-table__wrapper .datatable-text-content a:hover {
  cursor: url("@components/CursorArrow/cursorArrow.svg"), auto;
  background-color: unset;
  text-underline-offset: unset;
}
</style>
<style scoped>
.icon-container {
  cursor: pointer;
}

.icon-svg {
  display: block;
}
</style>

<template>
  <v-app id="app" class="application_style">
    <div @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
      <v-navigation-drawer
        ref="navigationBar"
        v-model="drawer"
        v-if="!$route.meta.hideMainSidebar && smAndUpVal"
        app
        :mini-variant-width="88"
        :mini-variant.sync="sidebarMini"
        :width="sidebarMini ? 88 : 325"
        :permanent="(isPermanent && !drawer) || smAndUpVal"
        :temporary="isPermanent && drawer"
        id="navigation-bar"
        :data-test="role == 'admin' ? 'adminSidebarItem' : 'sidebarItem'"
      >
        <v-sheet @click="sideToggleMenu" class="fill-height">
          <SideBar
            :sidebarOption="role == 'admin' ? adminSidebarItem : sidebarItem"
            :expandOnhover="false"
            :isMini="sidebarMini"
          ></SideBar>
          <div class="toggle-button" v-if="isHovered">
            <div
              class="toggle-button-class"
              v-if="!$route.meta.secondSidebar"
              @click="toggleMenu"
            >
              <img
                v-if="!sidebarMini"
                src="@components/assets/collapseIcon.svg"
                alt=""
              />
              <img v-else src="@components/assets/expandIcon.svg" alt="" />
            </div>
          </div>
        </v-sheet>
      </v-navigation-drawer>
    </div>
    <v-main
      data-testid="main-container"
      style="max-height: 100vh; overflow-y: scroll"
      class="d-flex justify-center ex-main-container"
    >
      <EmployeeSideBar
        v-if="
          $route.meta.secondSidebar && $route.meta.secondSidebar == 'employee'
        "
        :sidebarOption="employeeSidebar"
        @updateEmployeeSidebarCount="updateCount"
      />
      <NotFoundView
        v-if="getRouteError.code == 404 && getRouteError.visibility == true"
      />
      <router-view
        v-if="smAndUpVal && getRouteError.visibility == false"
        :key="$route.path"
      >
      </router-view>

      <ExLoader
        v-if="isLoading && getRouteError.visibility == false"
        text="Loading..."
        :type="loaderType"
      />

      <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
        <div class="mb-4 d-flex justify-center mt-8 mb-8">
          <img src="@/assets/login/logo.svg" />
        </div>
        <div class="desktop-only">Desktop only</div>
        <div class="only-desktop-message">
          Sorry, exiqtive.com only supports access via Desktops. No mobile
          support at this time
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "@components/common/SideBar.vue";
import EmployeeSideBar from "@components/common/EmployeeSideBar.vue";
import ExLoader from "@components/common/ExLoader.vue";
import NotFoundView from "@/views/NotFound.vue";
import { knock } from "@/plugins/knock";
import "vue-loading-overlay/dist/vue-loading.css";
import { createNamespacedHelpers } from "vuex";
import {
  getEmployeeById,
  getEmployeeChatter,
  getEmployeeBadges,
  // recalculateEmployeeReadiness,
} from "@/api";
import { usePositionStore } from "@/store/pinia/position";
import { useEmployeeStore } from "@/store/pinia/employee";
const { mapState: mapDataState } = createNamespacedHelpers("isPosition");
import { getEmployeeAssignment } from "@/api";
export default {
  name: "App",
  components: {
    SideBar,
    ExLoader,
    EmployeeSideBar,
    NotFoundView,
  },
  data: () => ({
    isHovered: false,
    drawer: false,
    sidebarMini: true,
    toggleMenuClicked: false,
    secondEmpBar: false,
    loader: "spinner",
    isOtherSidebarPresent: false,
    positionStore: usePositionStore(),
    employeeStore: useEmployeeStore(),
    role: "",
    adminSidebarItem: {
      type: "main",
      tItems: [
        {
          name: "ClientLogo",
          img: "clientlogo.svg",
          nameImg: "clientlogoname.svg",
          to: "",
        },
        {
          name: "Home",
          img: "home-01.svg",
          to: "/",
        },
        {
          name: "Accounts",
          img: "Company with Employees-grey.svg",
          to: "",
          count: "10",
        },
        {
          name: "Products",
          img: "box.svg",
          to: "",
          count: "10",
        },
        {
          name: "Subscriptions",
          img: "Subscription-grey.svg",
          to: "",
          count: "10",
        },
        {
          name: "Users",
          img: "users-01.svg",
          to: "",
          count: "10",
        },
        {
          name: "Partners",
          img: "Partnership-grey.svg",
          to: "",
          count: "10",
        },
      ],
      bItems: [
        {
          name: "Support",
          img: "life-buoy.svg",
          to: "",
        },
        {
          name: "Settings",
          img: "settings.svg",
          to: "",
        },
      ],
      cardItem: [
        {
          name: "Olivia Rhye",
          email: "olivia@untitledui.com",
          img: "Avatar.svg",
          to: "/login",
        },
      ],
    },
    idleTime: null,
    employeesData: [],
    totalChatterCount: 0,
    activeAssignments: 0,
    currentReadiness: 0,
    accessPermissionsValue: null,
  }),
  computed: {
    getRouteError() {
      return this.$store.state.routeErrorHandling.error;
    },
    isLoading() {
      return this.$store.state.isLoading.isLoading;
    },
    loaderType() {
      return this.$store.state.isLoading.loaderType;
    },
    storePositions() {
      return this.$store.getters["positionStore/positions"] || [];
    },
    currentEmployeeReadiness() {
      return this.$store.getters["employeeStore/currentEmployeeReadiness"];
    },
    currentEmployeeBadges() {
      return this.$store.getters["employeeStore/currentEmployeeBadges"];
    },
    ...mapDataState(["activePositionsCount"]),
    sidebarItem() {
      const currentAccount = this.accessPermissionsValue;
      const val = {
        type: "main",
        tItems: [
          {
            name: "ClientLogo",
            img: "clientlogo.svg",
            nameImg: "clientlogoname.svg",
            to: "",
          },
        ],
        bItems: [],
        cardItem: [],
      };
      const readinessItem = {
        name: "Readiness",
        img: "Readiness.svg",
        to: "",
        children: [
          {
            name: "Employees",
            img: "",
            to: "/readiness/employees",
            count: this.employeeStore.activeEmployeesCount,
          },
          // commented for v2.1 in ER-3450
          // {
          //   name: "Organization",
          //   img: "",
          //   to: "/readiness/organization",
          // },
          // {
          //   name: "Time to Readiness",
          //   img: "",
          //   to: "",
          // },
          // commented for v2.1 in ER-3450
          {
            name: "Catalogs",
            img: "",
            to: "/readiness/catalogs",
          },
        ],
      };
      const knowledgeItem = {
        name: "Knowledge",
        img: "Knowledge.svg",
        to: "",
        children: [
          {
            name: "Knowledge Catalog",
            img: "",
            to: "",
            count: 10,
          },
          {
            name: "Learning Catalog",
            img: "",
            to: "",
          },
        ],
      };
      const organizationItem = {
        name: "Organization",
        img: "Organization-chart.svg",
        to: "",
        children: [
          {
            name: "Employees",
            img: "",
            to: "/organization/employees",
            count: this.employeeStore.activeEmployeesCount,
          },
          {
            name: "Positions",
            img: "",
            to: "/organization/positions",
            count: this.positionStore.activePositionsCount,
          },
        ],
      };
      const performanceItem = {
        name: "Performance",
        img: "Performance.svg",
        to: "",
        children: [
          {
            name: "Scorecards",
            img: "",
            to: "",
            count: 10,
          },
          {
            name: "Tasks",
            img: "",
            to: "",
          },
          {
            name: "KPIs Catalog",
            img: "",
            to: "",
            count: 10,
          },
        ],
      };
      const compensationItem = {
        name: "Compensation",
        img: "Compensation.svg",
        to: "",
        children: [
          {
            name: "Employee Base Pay",
            img: "",
            to: "",
          },
          {
            name: "Position Pay Ranges",
            img: "",
            to: "",
            count: 10,
          },
          {
            name: "Pay Ranges",
            img: "",
            to: "",
          },
        ],
      };
      const settingsItem = {
        name: "Settings",
        img: "settings.svg",
        to: "/settings",
      };

      const myStuff = {
        name: "My Stuff",
        img: "MyStuff.svg",
        to: "",
        children: [
          {
            name: "As Employee",
            img: "",
            to: "/my-stuff/employee",
          },
          // {
          //   name: "As a Coach",
          //   img: "",
          //   to: "",
          // },
          // {
          //   name: "As a Master",
          //   img: "",
          //   to: "/my-stuff/master",
          // },
        ],
      };

      if (currentAccount && currentAccount?.user) {
        val.cardItem.push({
          name: `${currentAccount?.user?.first_name} ${currentAccount?.user?.last_name}`,
          firstName: currentAccount?.user?.first_name,
          lastName: currentAccount?.user?.last_name,
          email: currentAccount?.user?.email,
          img: "",
          to: "/login",
        });
      }

      const isHidden = true; // according to the use case
      if (!isHidden) {
        val.tItems.push(knowledgeItem);
        val.tItems.push(performanceItem);
        val.tItems.push(compensationItem);
      }

      if (currentAccount && currentAccount?.has_employee) {
        val.tItems.push(myStuff);
      }
      if (currentAccount?.has_employee && currentAccount?.has_master_badge) {
        myStuff.children.push({
          name: "As a Master",
          img: "",
          to: "/my-stuff/master",
        });
      }

      if (
        currentAccount &&
        (currentAccount?.is_owner || currentAccount?.is_co_owner)
      ) {
        val.tItems.push(organizationItem);
      }

      if (
        (currentAccount && currentAccount?.is_owner) ||
        currentAccount?.is_co_owner ||
        currentAccount?.has_readiness_access
      ) {
        val.tItems.push(readinessItem);
      }
      if (
        (currentAccount && currentAccount?.is_owner) ||
        currentAccount?.is_co_owner
      ) {
        val.bItems.push(settingsItem);
      }

      return val;
    },
    employeeSidebar() {
      const empId = this.$route.params.id;
      localStorage.setItem("emp_id", empId);
      const val = {
        type: "secondary",
        tItems: [
          {
            name: "Path to Readiness",
            img: "Readiness.svg",
            to: `/organization/employees/${empId}/pathtoreadiness`,
            count: `${this.$store.getters["employeeStore/currentEmployeeReadiness"]}%`,
          },
          {
            name: "Positions History",
            img: "Assignment.svg",
            to: `/organization/employees/${empId}/assignment`,
            count: this.activeAssignments,
          },

          {
            name: "Badges",
            img: "Badge.svg",
            to: `/organization/employees/${empId}/Badges`,
            count: `${this.$store.getters["employeeStore/currentEmployeeTotalBadges"]}`,
          },
          // {
          //   name: "Assignments",
          //   img: "Assignment.svg",
          //   to: `/organization/employees/${empId}/assignments`,
          //   count: this.activeAssignments,
          // },
        ]
          .filter((item) => item.name !== "Chatter")
          .concat({
            name: "Chatter",
            img: "Chatter.svg",
            to: `/organization/employees/${empId}/chatter`,
            count: this.totalChatterCount,
          }),
        cardItem: [
          {
            firstName: this.employeesData.first_name,
            lastName: this.employeesData.last_name,
            position: this.employeesData.title,
            email: "",
            photoUrl: this.employeesData.avatar,
            showAvailability: false,
            to: "/organization/employees",
            access: "has-access",
            status: this.employeesData.current_status,
            archived: this.employeesData.archived,
          },
        ],
        empData: this.employeesData,
      };
      return val;
    },
    smAndUpVal() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    lgAndUpVal() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    isPermanent() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  async mounted() {
    this.role = this.$route.meta.role;
    const localStorageToken = JSON.parse(localStorage.getItem("token"));
    if (localStorageToken) {
      await this.$store.dispatch("employeeStore/loadEmployees");
      await this.$store.dispatch("positionStore/loadPositions");
    }
    this.$root.$on("accessPermissionUpdated", (data) => {
      this.accessPermissionsValue = data;
    });
    if (this.$route.params) {
      const empId = this.$route.params.id;
      if (empId && localStorageToken) {
        await this.getEmployees(empId);
      }
      if (localStorageToken) {
        // await this.getPathToReadiness(empId);
        // await this.getEmployees(empId);
        // await this.getPathToReadiness(empId);
      }
    }
    this.startIdleTimer();
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
    this.$root.$off("accessPermissionUpdated");
  },
  watch: {
    drawer(val) {
      if (val && this.isPermanent) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    $route: {
      handler(newVal) {
        this.secondEmpBar = !!newVal.meta.secondSidebar;
        this.isOtherSidebarPresent = this.$route.meta.sideBarPresent;
        this.role = this.$route.meta.role;
        this.accessPermissionsValue = JSON.parse(
          localStorage.getItem("accessPermission")
        );
        if (newVal.meta.secondSidebar === "employee") {
          this.sidebarMini = true;
          this.drawer = false;
        } else {
          this.sidebarMini = false;
        }
      },
      deep: true,
      immediate: true,
    },
    "$vuetify.breakpoint.width"(newWidth) {
      if (this.$route.meta.secondSidebar == "employee") {
        this.sidebarMini = true;
        if (this.sidebarMini) {
          this.drawer = true;
        }
        this.secondEmpBar = false;
      } else {
        this.sidebarMini = newWidth < 1264;
        if (this.sidebarMini) {
          this.drawer = false;
        }
        this.secondEmpBar = false;
      }
    },
    storePositions() {
      this.updateActiveAssignments();
    },
    "$route.params.id"(newId) {
      if (newId) {
        this.getEmployees(newId);
        this.updateActiveAssignments();
      }
    },
  },
  methods: {
    handleMouseOver() {
      if (this.$route.meta.secondSidebar) {
        if (this.sidebarMini) {
          this.sidebarMini = false;
        }
      } else {
        this.isHovered = true;
      }
    },
    handleMouseLeave() {
      if (this.$route.meta.secondSidebar) {
        if (!this.sidebarMini) {
          this.sidebarMini = true;
        }
      } else {
        this.isHovered = false;
      }
    },
    async getEmployeesChatter(accountID, empId) {
      const { data } = await getEmployeeChatter(accountID, empId, 1);
      if (data && data.count) {
        this.totalChatterCount = data.count;
      }
    },
    async getEmployeesAssignments(accountID, empId) {
      const val = false;
      const { data } = await getEmployeeAssignment(accountID, empId, val);
      this.activeAssignments = data?.length;
    },
    async getEmployeesBadges(accountID, empId) {
      try {
        const { data } = await getEmployeeBadges(accountID, empId);
        this.$store.dispatch("employeeStore/currentEmployeeBadges", data);
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getEmployees(empId) {
      const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
      const { data } = await getEmployeeById(accountID, empId);
      if (data) {
        await this.$store.dispatch(
          "employeeStore/setCurrentEmployeeAction",
          data
        );
        this.currentReadiness = data?.current_readiness;
        this.employeesData = data;
        this.getEmployeesChatter(accountID, empId);
        this.getEmployeesAssignments(accountID, empId);
        this.getEmployeesBadges(accountID, empId);
      }
    },
    toggleMenu() {
      if (!this.isPermanent) {
        this.sidebarMini = !this.sidebarMini;
      } else {
        this.toggleMenuClicked = true;
        this.sidebarMini = !this.sidebarMini;
        if (this.sidebarMini === false) {
          this.drawer = true;
        } else {
          this.drawer = false;
        }
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.toggleMenuClicked = false;
        });
      }, 100);
    },
    sideToggleMenu() {
      if (this.isPermanent && !this.drawer && !this.toggleMenuClicked) {
        this.drawer = true;
        this.sidebarMini = false;
      }
    },
    handleOutsideClick(event) {
      if (this.isPermanent && this.drawer) {
        const navBarComponent = this.$refs.navigationBar;
        if (navBarComponent) {
          const navBarEl = navBarComponent.$el || navBarComponent;
          if (
            navBarEl &&
            navBarEl.contains &&
            !navBarEl.contains(event.target)
          ) {
            this.drawer = false;
            this.sidebarMini = true;
          }
        }
      }
    },
    startIdleTimer() {
      window.addEventListener("load", this.resetTimer, true);
      window.addEventListener("mousemove", this.resetTimer, true);
      window.addEventListener("mousedown", this.resetTimer, true);
      window.addEventListener("click", this.resetTimer, true);
      window.addEventListener("keydown", this.resetTimer, true);
      window.addEventListener("scroll", this.resetTimer, true);
    },
    resetTimer() {
      clearTimeout(this.idleTime);
      this.idleTime = setTimeout(this.logout, 600000);
    },
    logout() {
      if (this.$route.path != "/login") {
        localStorage.clear();

        knock.feeds.teardownInstances();
        knock.teardown();

        this.$router.push({ name: "Login" });
      }
    },
    updateActiveAssignments() {
      // const empId = this.$route.params.id;
      // const positions = [...this.storePositions];
      // this.activeAssignments = positions.filter(
      //   (position) =>
      //     position.status === "active" &&
      //     position.assigned_employees?.some((employee) => employee.id == empId)
      // ).length;
    },
    // async getPathToReadiness(empId) {
    //   const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    //   const val = true;
    //   const { data } = await recalculateEmployeeReadiness(
    //     accountID,
    //     empId,
    //     val
    //   );
    //   if (data) {
    //     this.currentReadiness = data.current_readiness;
    //   }
    // },
    updateCount() {
      const empId = this.$route.params.id;
      this.getEmployees(empId);
    },
  },
};
</script>
<style>
.v-main__wrap {
  display: flex;
}
.application_style {
  font-family: "Inter" !important;
}
.ex-main-container {
  scrollbar-width: none;
}
.ex-main-container::-webkit-scrollbar {
  display: none;
}
.desktop-only {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #475467;
}
.only-desktop-message {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #475467;
}
.toggle-button {
  position: absolute;
  right: -13px;
  top: 43px;
  z-index: 9;
}
.plus-icon-small {
  vertical-align: middle;
}
.v-navigation-drawer {
  overflow: visible;
}
.right-side-card {
  position: relative;
  top: 25px;
  left: -15px;
}
.employee-status-side {
  left: 120px;
  top: -10px;
}
.employee-back-icon {
  top: -29px;
}
.employee-three-dots {
  top: -27px;
}
.toggle-button-class {
  display: flex;
  padding: 3.673px;
  justify-content: center;
  align-items: center;
  gap: 5.878px;
  border-radius: 5.878px;
  border: 0.735px solid #7f56d9;
  background: #7f56d9;
  box-shadow: 0px 0.735px 1.469px 0px rgba(16, 24, 40, 0.05);
}
.toggle-button-class:hover {
  background-color: #5b2cc5;
  cursor: url("../assets/cursorArrow.svg"), auto;
}
</style>

<template>
  <v-container fluid>
    <h4 class="checklist-view-title">Readiness Checklist</h4>
    <div class="d-flex gap-2 flex-row align-center">
      <ProficiencyBadge
        level="Apprentice"
        dot="Good Standing"
        view="ReadOnly"
      />

      <InspectionIssueBadge
        currentBadgeLevel="coach"
        currentBadgeStatus="good_standing"
        readyFor="professional"
        :checkpoints="checkPoints"
      />

      <ResAssignmentWithPath
        class="ml-5"
        resPathType="responsibility"
        responsibilityCode="#9999"
        responsibilityName="Responsibility"
        roleCode="9999"
        roleName="Test Role"
        positionCode="0001"
        positionName="Test Position"
      />
    </div>
    <div class="mx-5">
      <CatalogsMenu
        :items="menuItems"
        :value="selectedTab"
        @onChange="changeTab($event)"
      />
    </div>
    <v-tabs-items v-model="selectedTab" class="custom-tabs">
      <v-tab-item v-for="(item, index) in menuItems" :key="index">
        <v-card elevation="0" v-if="selectedTab === 0">
          <UniversalTable
            :config="tableConfig.tableParams.value"
            :items="filteredMasteredResponsibilities"
            @onSearch="viewState.state.filters.searchText = $event"
            @onClick="viewState.state.filters.showFilterDialog = true"
            @removeFilter="viewState.removeFilters"
            @removeAllFilters="viewState.state.filters.values = []"
            :emptyObjectData="emptyObjectData"
          >
          </UniversalTable>
          <SearchModal
            :dialog="viewState.state.filters.showFilterDialog"
            @back="viewState.state.filters.showFilterDialog = false"
            @checkedItemsChanged="viewState.applyFilters"
            :clearSelection="viewState.state.filters.clearSelection"
            filterType="Badges"
            :filterGroups="viewState.state.filters.filterGroups"
            :statusOptions="[]"
            :modalWidth="800"
            :defaultSelected="viewState.state.filters.values"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script setup>
import { computed, ref } from "vue";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import { useTableConfig } from "@/views/ReadinessCheckListView/composables/useTableConfig.js";
import { useViewState } from "@/views/ReadinessCheckListView/composables/useReadinessChecklistViewState.js";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import CatalogsMenu from "@components/CatalogsMenu/CatalogsMenu.vue";
import InspectionIssueBadge from "@components/InspectionIssueBadge/InspectionIssueBadge.vue";
import ProficiencyBadge from "@components/ProficiencyBadge/ProficiencyBadge.vue";
import ResAssignmentWithPath from "@components/ResAssignmentWithPath/ResAssignmentWithPath.vue";

const tableConfig = useTableConfig();
const viewState = useViewState();
const emptyObjectData = {
  singular: "Badge",
  plural: "Badges",
};

const menuItems = [
  { title: "Checklist" },
  { title: "Available Inspectors", count: 2 },
];

const selectedTab = ref(0);

const dataStream = [
  {
    id: 1,
    check_point: {
      inspected_by_avatar:
        "https://d2ox6kgfo0vgc9.cloudfront.net/803006b5-6324-4b06-b79b-8a634a9c2680.png",
      inspected_by: "zain nadeem",
      inspected_on: "2025-03-12T05:00:31.541780Z",
      checkpoint_status: "Passed",
    },
    evaluation_instruction: {
      rowId: 1,
      status: "Pending Evaluation",
      level: "1",
      criteria: "This is some criteria to evaluate by",
      instructions:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. sit amet consectetur adipisicing elit. Necessitatibus voluptatibus aliquam quod, Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus voluptatibus aliquam quod",
      failed_by: "",
      failed_on: "",
      failed_reason: "",
      reset_by: "",
      reset_on: "",
      reset_reason: "",
    },
  },
  {
    id: 2,
    check_point: {
      inspected_by_avatar:
        "https://d2ox6kgfo0vgc9.cloudfront.net/803006b5-6324-4b06-b79b-8a634a9c2680.png",
      inspected_by: "zain nadeem",
      inspected_on: "2025-03-12T05:00:31.541780Z",
      checkpoint_status: "Failed",
    },
    evaluation_instruction: {
      rowId: 2,
      status: "Failed",
      level: "1",
      criteria: "This is some criteria to evaluate by",
      instructions:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus voluptatibus aliquam quod, Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus voluptatibus aliquam quod",
      failed_by: "",
      failed_on: "",
      failed_reason: "this is a test reason for failure",
      reset_by: "",
      reset_on: "",
      reset_reason: "",
    },
  },
];
const filteredMasteredResponsibilities = computed(() => {
  let allMasteredResponsibilities = dataStream;
  if (
    viewState.state.filters.searchText &&
    viewState.state.filters.searchText.length > 0
  ) {
    const searchText = viewState.state.filters.searchText.toLowerCase();
    allMasteredResponsibilities = allMasteredResponsibilities.filter((el) => {
      const fullName = `${el.name} ${el.code}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        el.name.toLowerCase().includes(searchText) ||
        el.code.toLowerCase().includes(searchText)
      );
    });
  }
  allMasteredResponsibilities = allMasteredResponsibilities.sort((a, b) => {
    const firstNameA = a?.name?.toLowerCase() || "";
    const firstNameB = b?.name?.toLowerCase() || "";
    const lastNameA = a?.code?.toLowerCase() || "";
    const lastNameB = b?.code?.toLowerCase() || "";
    if (firstNameA < firstNameB) return -1;
    if (firstNameA > firstNameB) return 1;
    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });
  return allMasteredResponsibilities;
});

const checkPoints = [
  {
    id: 16,
    evaluation_status: "passed",
    evaluation_reason: null,
    created_on: "2025-03-10T16:07:26.499077Z",
    updated_on: "2025-03-10T16:07:39.988415Z",
    proficiency_level: 1,
    criteria: "aaaa",
    instructions: "<p>aaaa</p>",
    status: "active",
    sequence: 10,
    created_by: null,
    updated_by: null,
    account: 2,
    responsibility: 49,
  },
  {
    id: 17,
    evaluation_status: "passed",
    evaluation_reason: null,
    created_on: "2025-03-10T16:07:34.785000Z",
    updated_on: "2025-03-10T16:14:15.109110Z",
    proficiency_level: 2,
    criteria: "bbb",
    instructions: "<p>bbbb</p>",
    status: "active",
    sequence: 10,
    created_by: null,
    updated_by: null,
    account: 2,
    responsibility: 49,
  },
  {
    id: 18,
    evaluation_status: "passed",
    evaluation_reason: null,
    created_on: "2025-03-10T16:07:34.785000Z",
    updated_on: "2025-03-10T16:14:15.109110Z",
    proficiency_level: 3,
    criteria: "bbb",
    instructions: "<p>bbbb</p>",
    status: "active",
    sequence: 10,
    created_by: null,
    updated_by: null,
    account: 2,
    responsibility: 49,
  },
  {
    id: 19,
    evaluation_status: null,
    evaluation_reason: null,
    created_on: "2025-03-10T16:07:34.785000Z",
    updated_on: "2025-03-10T16:14:15.109110Z",
    proficiency_level: 4,
    criteria: "bbb",
    instructions: "<p>bbbb</p>",
    status: "active",
    sequence: 10,
    created_by: null,
    updated_by: null,
    account: 2,
    responsibility: 49,
  },
];
const changeTab = (tab) => {
  selectedTab.value = tab;
};
</script>
<style scoped>
.icon-container {
  cursor: pointer;
}

.checklist-view-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 32px;
}

:deep(.assigned-date-text) {
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 18px;
  color: #344054;
  margin-top: 2px;
  margin-bottom: 0px !important;
  text-align: start !important;
}
</style>

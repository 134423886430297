import { reactive } from "vue";
import { useFilters } from "@/composables/filters/useFilters";

let instance = null;

export function useViewState() {
  if (instance) return instance;
  const filter = useFilters();
  const state = reactive({
    filters: {
      values: [],
      searchText: "",
      showFilterDialog: false,
      key: "readinessEmployees",
      clearSelection: "",
      filterGroups: [
        {
          label: "Status",
          items: [
            { label: "Current", value: "current" },
            { label: "Expired", value: "expired" },
            { label: "Removed", value: "removed" },
          ],
        },
      ],
    },
    loading: {
      table: false,
    },
    table: {
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
  });

  const getEmployeeCardProps = (data) => {
    return {
      firstName: data?.employee?.first_name,
      lastName: data?.employee?.last_name,
      position: data?.employee?.title,
      photoUrl: data?.employee?.avatar,
      availability: data?.employee?.availability,
      showAvailability: true,
      nameUrl: `data?.employees?/${data?.employee?.id}/pathtoreadiness`,
      showLinkUnderline: false,
      hideAvailabilitySelector:
        data?.employee?.primary_positions?.length > 0 || false,
      isAllocated: data?.employee?.primary_positions?.length > 0 || false,
    };
  };

  const getBadgeLevel = (level) => {
    const levels = ["Novice", "Apprentice", "Professional", "Coach", "Master"];
    if (levels.includes(level)) {
      return level;
    } else {
      return levels[level];
    }
  };

  const formatProficiencyBadge = (badge) => {
    if (badge === "forced") {
      return "Removed";
    }
    if (!badge) {
      return "Editable";
    }
    return badge.charAt(0).toUpperCase() + badge.slice(1);
  };

  const applyFilters = (filters) => {
    const newArray = filters
      .map((item) => {
        if (item.value !== null) {
          if (typeof item === "string") {
            return item;
          } else if (
            typeof item === "object" &&
            // eslint-disable-next-line no-prototype-builtins
            item.hasOwnProperty("value")
          ) {
            return item.value;
          }
        }
      })
      .filter(Boolean);
    filter.setFilters({ key: state.filters.key, filters: newArray });
    state.filters.values = newArray;
    state.filters.showFilterDialog = false;
  };

  const removeFilters = (removedFilters) => {
    filter.setFilters({
      key: state.filters.key,
      filters: state.filters.values,
    });
    state.filters.clearSelection = removedFilters;
  };

  const getOrgObjectCardProps = (obj) => {
    return {
      code: obj.code,
      name: obj.name,
      showIcon: false,
      removeUnderlineToName: true,
    };
  };

  const getProficiencyBadgeProps = (obj) => {
    return {
      level: getBadgeLevel(obj?.badge?.level),
      dot: obj?.badge?.status,
      view: formatProficiencyBadge(obj?.badge?.remove_method),
      expiration_date: obj?.badge?.expiration_date,
      has_expiration_if_obsolete: obj.badge?.expires_when_obsolete,
    };
  };

  const getIssuedProps = (obj) => {
    return {
      name: obj?.badge?.granted_by?.name,
      picture: obj?.badge?.granted_by?.picture,
      userType: obj?.badge?.granted_by?.userType,
      displayDate: formatDateToDisplay(obj?.badge?.granted_on),
    };
  };

  const getCheckPointCounterProps = (obj) => {
    return {
      countApprentice: obj.checkpoint.apprentice,
      countProfessional: obj.checkpoint.professional,
      countCoach: obj.checkpoint.coach,
      countMaster: obj.checkpoint.master,
    };
  };

  const getRemovedProps = (obj) => {
    return {
      name: obj?.badge?.removed_by?.name,
      picture: obj?.badge?.removed_by?.picture,
      userType: obj?.badge?.removed_by?.userType,
      displayDate: formatDateToDisplay(obj?.badge?.removed_on),
    };
  };

  const formatDateToDisplay = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);

    if (isNaN(date)) return "Invalid Date";

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const getInspectionStatusBadgeProps = (obj) => {
    return {
      inspected_by_avatar: obj?.check_point?.inspected_by_avatar,
      inspected_by: obj?.check_point?.inspected_by,
      inspected_on: obj?.check_point?.inspected_on,
      checkpoint_status: obj?.check_point?.checkpoint_status,
      reason: obj?.evaluation_instruction?.failed_reason,
    };
  };

  const getEvaluationInstructionCard = (obj) => {
    return {
      rowId: obj.evaluation_instruction.rowId,
      status: obj.evaluation_instruction.status,
      level: obj.evaluation_instruction.level,
      criteria: obj.evaluation_instruction.criteria,
      instructions: obj.evaluation_instruction.instructions,
      failed_by: obj.evaluation_instruction.failed_by,
      failed_on: obj.evaluation_instruction.failed_on,
      failed_reason: obj.evaluation_instruction.failed_reason,
      reset_by: obj.evaluation_instruction.reset_by,
      reset_on: obj.evaluation_instruction.reset_on,
      reset_reason: obj.evaluation_instruction.reset_reason,
    };
  };

  // Métodos para filtros
  const updateFilter = (filterName, value) => {
    if (filterName in state.filters) {
      state.filters[filterName] = value;
    }
  };

  const clearFilters = () => {
    state.filters = {
      status: "all",
      department: null,
      position: null,
    };
  };

  // Métodos para loading
  const setLoading = (key, value) => {
    if (key in state.loading) {
      state.loading[key] = value;
    }
  };

  instance = {
    state,
    updateFilter,
    clearFilters,
    setLoading,
    getOrgObjectCardProps,
    getCheckPointCounterProps,
    applyFilters,
    removeFilters,
    getIssuedProps,
    getEmployeeCardProps,
    getProficiencyBadgeProps,
    getRemovedProps,
    getInspectionStatusBadgeProps,
    getEvaluationInstructionCard,
  };
  return instance;
}

<template>
  <v-container fluid>
    <PageTitle title="Employee Path to Readiness" style="margin-bottom: 10px" />
    <PathToReadinessBar
      :readiness="employeeData?.current_readiness"
      :needsRecalc="employeeData?.needs_recalc"
      @onRecalc="onEmployeeReadiness()"
      :loading="loading.readinessBar"
    />
    <div class="custom-path">
      <div v-if="dataSource.length === 0 && employeeData">
        <NoRecordFound
          :title="''"
          :subtitle="''"
          :showSpecificMessage="title"
          message=""
          class="no-record-found"
          :shouldShowAddAction="true"
          :noBorder="true"
          :showActiveCounter="false"
          :showClearAction="false"
          :btnTitle="btnTitle"
          :addPrefix="false"
          :description="description"
          :plusIcon="false"
          @onAction="handleButtonClick"
        />
      </div>
      <PathToReadinessTreeList
        v-else
        :dataSource="dataSource"
        :topLevel="topLevel"
        :removeBorder="removeBorder"
        :dropdownItems="dropdownItems"
        @optionClick="onOptionClick"
        :showRespBadge="true"
        @loadData="loadData()"
        :loading="loading.initial"
      />
    </div>
    <EmployeeModal
      :dialog="isForceBadge"
      label="Responsibility"
      :name="currentResponsibility?.name"
      :empForceBadgeClass="true"
      :hideEmployeePicker="true"
      :value="[1]"
      @back="isForceBadge = false"
      @onAction="onClickForceBadge"
    />
    <EvalResponsibilityModal
      v-if="isInspectResModal"
      :dialog="isInspectResModal"
      :badges="badges"
      :checkpointsData="checkpointsData"
      :evalModalHeaderState="evalModalHeaderState"
      :evalModalEmpState="evalModalEmpState"
      @closeModal="isInspectResModal = false"
      :currentResponsibilityId="currentResponsibility?.id"
      :assignmentData="false"
      @grantBadge="grantOrForceBadge"
      @reloadData="reloadCheckpointsData"
    />
    <ActivateEmployee
      :show="activateEmployee"
      :employee="currenntEmployee"
      @onClose="onCloseModal"
      @onActiveEmployee="handleActivateEmployee"
    ></ActivateEmployee>
    <ReActivateEmployee
      :show="reActivateEmployee"
      :employee="currenntEmployee"
      @onClose="onCloseModal"
      @onActiveEmployee="reactivateEmployee"
    ></ReActivateEmployee>
    <ExEmployeePrimaryAssignmentWizard
      label="Position"
      v-model="assignPosition"
      :items="eligiblePositions"
      :currentEmployee="employeeData"
      @onAssignPosition="onAssignPositionToEmployee"
    />
  </v-container>
</template>
<script>
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import PathToReadinessTreeList from "@components/DataTable/PathToReadinessTreeList.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import PathToReadinessBar from "@components/PathToReadinessBar/PathToReadinessBar.vue";
import EmployeeModal from "@components/EmployeeModal/EmployeeModal.vue";
import EvalResponsibilityModal from "@components/EvalResponsibilityModal/EvalResponsibilityModal.vue";
import {
  getResponsibilityChecklist,
  getListActiveCheckpointsEval,
  createProficiencyBadge,
  activateEmployee,
  reactivateEmployee,
} from "@/api";
import { getListofResponsibility } from "@/api";
import {
  getEmployeeAssignment,
  getEmployeeById,
  recalculateEmployeeReadiness,
  addPositionAssignment,
} from "@/api";
import ExEmployeePrimaryAssignmentWizard from "@components/PositionPicker/ExEmployeePrimaryAssignmentWizard.vue";
import PageTitle from "@components/common/PageTitle.vue";
import ActivateEmployee from "@components/modals/ActiveEmployee.vue";
import ReActivateEmployee from "@components/modals/ReActivateEmployee.vue";
import { usePositionStore } from "@/store/pinia/position";
import { useEmployeeStore } from "@/store/pinia/employee";
export default {
  name: "EmployeePathToReadinessView",
  components: {
    PathToReadinessTreeList,
    PathToReadinessBar,
    PageTitle,
    EmployeeModal,
    EvalResponsibilityModal,
    NoRecordFound,
    ActivateEmployee,
    ReActivateEmployee,
    ExEmployeePrimaryAssignmentWizard,
  },
  data() {
    return {
      dataSource: [],
      topLevel: "position",
      removeBorder: false,
      currentReadiness: null,
      currentSelectRes: null,
      currentResponsibility: null,
      isForceBadge: false,
      showEmployeeIssueBadge: false,
      isInspectResModal: false,
      allResponsibilities: [],
      checkpointsData: [],
      evalModalEmpState: null,
      evalModalHeaderState: null,
      activateEmployee: false,
      reActivateEmployee: false,
      assignPosition: false,
      currenntEmployee: null,
      positionStore: usePositionStore(),
      employeeStore: useEmployeeStore(),
      badges: [
        {
          badge_id: "Apprentice",
          type: "Forced",
        },
        {
          badge_id: "Professional",
          is_force: true,
        },
        {
          badge_id: "Coach",
        },
        {
          badge_id: "Master",
          is_force: false,
        },
      ],
      employeeData: null,
      loading: {
        initial: false,
        readinessBar: false,
        responsibilities: {},
        assignments: false,
        forceBadge: false,
        employeeStatus: false,
        checkpoint: false,
      },
    };
  },
  mixins: [dropdownMixin],
  async mounted() {
    await this.loadData();
    await this.onEmployeeReadiness();
  },
  computed: {
    eligiblePositions() {
      if (!this.employeeStore.currentEmployee) return [];

      const allPositions = this.positionStore.positions;
      let allocationSum = 0;

      if (this.employeeStore.currentEmployee.primary_positions?.length > 0) {
        this.employeeStore.currentEmployee.primary_positions.forEach((el) => {
          allocationSum += el.allocation;
        });
      }

      const employeeVacant =
        this.employeeStore.currentEmployee.availability - allocationSum;
      const allocationsArray = [];

      this.employeeStore.employees.forEach((item) => {
        if (item.primary_positions?.length > 0) {
          item.primary_positions.forEach((positionAssignment) => {
            const newEl = {
              id: positionAssignment.position,
              allocation: positionAssignment.allocation,
            };
            const itemExist = allocationsArray.some((el) => el.id === newEl.id);
            if (!itemExist) {
              allocationsArray.push(newEl);
            }
          });
        }
      });

      if (allPositions) {
        const positionsWithAllocation = allPositions.map((el) => {
          const allocationItem = allocationsArray.find(
            (allocItem) => allocItem.id === el.id
          );
          return {
            ...el,
            allocation: allocationItem ? allocationItem.allocation : 0,
          };
        });

        return positionsWithAllocation.filter((el) => {
          const positionVacancy = (el?.capacity || 0) - (el?.allocation || 0);
          return (
            positionVacancy &&
            employeeVacant >= positionVacancy &&
            positionVacancy >= 1 && // does not show positions with 0.5 for now
            el.status == "active"
          );
        });
      }
      return [];
    },

    statusConfig() {
      const statuses = {
        draft: {
          title: "Employee Not Yet Activated",
          description:
            "This employee is currently in draft status. Activate them to unlock the Path to readiness and start tracking their progress.",
          btnTitle: "Activate",
        },
        terminated: {
          title: "Employee Status: Terminated",
          description:
            "This employee’s Path to readiness is unavailable because they have been terminated. Re-activate them to resume tracking their progress.",
          btnTitle: "Re-Activate",
        },
        active: {
          title: "No Position Assigned Yet",
          description:
            "To track this employee’s readiness, assign them to a position first.",
          btnTitle: "Assign",
        },
      };

      return (
        statuses[this.employeeData?.current_status] || {
          title: "",
          description: "",
          btnTitle: "",
        }
      );
    },

    title() {
      return this.statusConfig.title;
    },

    description() {
      return this.statusConfig.description;
    },

    btnTitle() {
      return this.statusConfig.btnTitle;
    },
  },
  methods: {
    handleButtonClick() {
      const { id, first_name, last_name, availability, avatar, title } =
        this.employeeData;
      const employeeObj = {
        id,
        firstName: first_name,
        lastName: last_name,
        availability,
        picture: avatar,
        title: title,
      };
      this.currenntEmployee = JSON.parse(JSON.stringify(employeeObj));
      if (this.employeeData.current_status === "draft") {
        this.activateEmployee = true;
      }
      if (this.employeeData.current_status === "terminated") {
        this.reActivateEmployee = true;
      }
      if (this.employeeData.current_status === "active") {
        this.assignPosition = true;
      }
    },
    onCloseModal() {
      this.activateEmployee = false;
      this.reActivateEmployee = false;
      this.assignPosition = false;
    },
    dropdownItems(data) {
      const items = [];
      if (data?.data?.type === "responsibility" && !data?.data?.badge) {
        items.push({
          name: "Issue Badge",
          value: "issue_badge_action",
          icon: "plainminimalistic.svg",
          order: 2,
        });
      }
      return items;
    },
    async reloadCheckpointsData(data) {
      const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
      const data3 = await getListActiveCheckpointsEval(
        accountID,
        data.responsibility,
        data.employee
      );
      await this.formatChecklistData(data3);
      await this.loadAllResponsibilitiesCheckpointsData([
        {
          id: data.responsibility,
          code: this.currentResponsibility.code_str,
        },
      ]);
    },
    async grantOrForceBadge(badgeData) {
      const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
      this.isInspectResModal = false;
      await createProficiencyBadge(accountID, badgeData.payload);
      await this.loadData();
      await this.onEmployeeReadiness();
    },
    async loadData() {
      const empId = this.$route?.params?.id;
      const authUser = JSON.parse(localStorage.getItem("user"));
      if (this.dataSource.length === 0) {
        this.loading.initial = true;
      }

      try {
        await Promise.all([
          this.getAuthUser(empId),
          this.getEmpAssignments(authUser?.id, empId),
        ]);

        if (this.allResponsibilities.length === 0) {
          await this.getAllResponsibilities();
        }

        const responsibilitiesIds = this.allResponsibilities.map((el) => ({
          id: el.id,
          code: el.code_str,
        }));

        this.loadAllResponsibilitiesCheckpointsData(responsibilitiesIds);
        this.employeeStore.loadEmployees();
        this.positionStore.loadPositions();
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.loading.initial = false;
      }
    },
    async loadAllResponsibilitiesCheckpointsData(responsibilitiesIds) {
      const empId = this.$route?.params?.id;
      const accountID = JSON.parse(localStorage.getItem("currentAccountID"));

      for (const resp of responsibilitiesIds) {
        this.$set(this.loading.responsibilities, resp.code, true);

        try {
          let checkpointsData = await this.loadAllCheckpointsEvaluationData(
            accountID,
            empId,
            resp.id
          );

          if (checkpointsData && checkpointsData.length > 0) {
            checkpointsData = checkpointsData.map((el) => ({
              id: el.id,
              evaluation_status: el.evaluation_status,
              proficiency_level: el.proficiency_level,
              status: el.status,
              responsibility: el.responsibility,
            }));

            const indexResponsibility = this.dataSource.findIndex(
              (el) => el.data.code == resp.code
            );

            if (indexResponsibility > -1) {
              const newElement = {
                ...this.dataSource[indexResponsibility],
                checkpointsData,
              };
              this.dataSource.splice(indexResponsibility, 1, newElement);
            }
          }
        } catch (error) {
          console.error(
            `Error loading checkpoints for responsibility ${resp.id}:`,
            error
          );
        } finally {
          this.$set(this.loading.responsibilities, resp.code, false);
        }
      }
    },
    async loadAllCheckpointsEvaluationData(
      accountId,
      employeeId,
      responsibilityId
    ) {
      const response = await getListActiveCheckpointsEval(
        accountId,
        responsibilityId,
        employeeId
      );
      return response.data;
    },
    async getEmpAssignments(userId, empId) {
      const val = true;
      const { data } = await getEmployeeAssignment(userId, empId, val);

      if (data.length > 0) {
        this.dataSource = this.transformData(data);
      }
    },
    async getAuthUser(empId) {
      this.loading.readinessBar = true;
      try {
        const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
        const { data } = await getEmployeeById(accountID, empId);
        if (data) {
          this.$store.dispatch("employeeStore/setCurrentEmployeeAction", data);
          this.employeeStore.setCurrentEmployee(data);
          this.employeeData = data;
          this.currentReadiness = { current_readiness: data.current_readiness };
        }
      } catch (error) {
        console.error("Error fetching employee:", error);
      } finally {
        this.loading.readinessBar = false;
      }
    },
    async onEmployeeReadiness() {
      this.loading.readinessBar = true;
      try {
        const empId = this.$route?.params?.id;
        const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
        const authUser = JSON.parse(localStorage.getItem("user"));
        const val = true;

        const { data } = await recalculateEmployeeReadiness(
          accountID,
          empId,
          val
        );
        this.employeeData = data;

        if (this.employeeData) {
          this.currentReadiness = {
            current_readiness: this.employeeData.current_readiness,
          };
        }

        Promise.all([
          this.getAuthUser(empId),
          this.getEmpAssignments(authUser?.id, empId),
        ]).catch((error) => {
          console.error(
            "Error updating data after readiness calculation:",
            error
          );
        });
      } catch (error) {
        console.error("Error calculating readiness:", error);
      } finally {
        this.loading.readinessBar = false;
      }
    },
    async onEmployeeDetails() {
      const empId = this.$route?.params?.id;
      await this.getAuthUser(empId);
    },

    transformData(dataArray) {
      const result = [];
      const today = new Date();

      const existingRespMap = {};
      this.dataSource.forEach((item) => {
        if (
          item.data &&
          item.data.code &&
          item.data.type === "responsibility" &&
          item.checkpointsData
        ) {
          existingRespMap[item.data.code] = item;
        }
      });

      dataArray.forEach((data) => {
        const isNew =
          new Date(data.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
          today.getTime();

        const mainObject = {
          id: data.id, // Use API ID
          parentId: -1, // Root-level has no parent
          data: {
            type: data.assignment_type,
            commitmentLevel: data.commitment_level,
            assignmentType: data.assignment_type,
            importanceLevel: data.importance,
            importanceSelector: true,
            totalSteps: 3,
            code: data.position_code,
            name: data.position_name,
            isNew: isNew,
            isAccepted: false,
            acceptanceStatus: "pending",
          },
          assignment_id: data.id,
          status: data.status,
          readiness:
            data.current_readiness === 0
              ? this.employeeData?.current_readiness
              : data.current_readiness,
        };
        result.push(mainObject);

        if (data.role_assignments) {
          data.role_assignments.forEach((role) => {
            const isRoleNew =
              new Date(role.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
              today.getTime();

            const roleObject = {
              id: `role-${role.id}`, // Ensure uniqueness with a prefix
              parentId: data.id, // Set parent ID to main object
              data: {
                type: role.assignment_type,
                commitmentLevel: role.commitment_level,
                assignmentType: role.assignment_type,
                importanceLevel: role.importance,
                importanceSelector: true,
                totalSteps: 3,
                code: role.role_code,
                name: role.role_name,
                isNew: isRoleNew,
                isAccepted: isRoleNew,
                acceptanceStatus: "pending",
                assignment_id: data.id,
              },
              readiness: role.current_readiness,
            };
            result.push(roleObject);

            if (role.responsibility_assignments) {
              role.responsibility_assignments.forEach((resp) => {
                const isRespNew =
                  new Date(resp.assigned_on).getTime() +
                    10 * 24 * 60 * 60 * 1000 >
                  today.getTime();
                const responsibilityObject = {
                  id: `resp-${resp.id}`, // Ensure uniqueness
                  parentId: `role-${role.id}`, // Correctly set parent ID
                  data: {
                    type: resp.assignment_type,
                    commitmentLevel: resp.commitment_level,
                    assignmentType: resp.assignment_type,
                    importanceLevel: resp.importance,
                    importanceSelector: true,
                    totalSteps: 3,
                    code: resp.responsibility_code,
                    name: resp.responsibility_name,
                    isNew: isRespNew,
                    isAccepted: isRespNew,
                    acceptanceStatus: "pending",
                    assignment_id: data.id,
                    badge: resp.badge,
                  },
                  status: resp.status,
                  readiness: resp.current_readiness,
                };
                const existingResp = existingRespMap[resp.responsibility_code];
                if (existingResp && existingResp.checkpointsData) {
                  responsibilityObject.checkpointsData =
                    existingResp.checkpointsData;
                }

                result.push(responsibilityObject);
              });
            }
          });
        }
      });

      return result;
    },

    async getAllResponsibilities() {
      try {
        const accountID = localStorage.getItem("currentAccountID");
        const data = await getListofResponsibility(accountID);
        this.allResponsibilities = data.data.results;
      } catch (error) {
        console.error("Error fetching responsibilities:", error);
      }
    },
    onOptionClick(item) {
      this.onIssueBadge(item);
    },
    async onIssueBadge(item) {
      let currentEmployee = null;
      this.currentSelectRes = item;
      this.currentResponsibility = this.allResponsibilities.find(
        (responsibility) => responsibility.code_str === item.data.data.code
      );
      const account_id = JSON.parse(localStorage.getItem("currentAccountID"));
      const emp_id = Number(localStorage.getItem("emp_id")); // Convert to number if needed
      const res_id = this.currentResponsibility.id;

      const data = await getResponsibilityChecklist(account_id, res_id);
      const hasActiveStatus = data.data.results.some(
        (item) => item.status === "active"
      );
      if (hasActiveStatus) {
        const data3 = await getListActiveCheckpointsEval(
          account_id,
          res_id,
          emp_id
        );
        await this.formatChecklistData(data3);
        this.showEmployeeIssueBadge = false;
        this.isForceBadge = false;
        this.isInspectResModal = true;
        this.evalModalHeaderState = {
          responsibilityCode: this.currentResponsibility.code,
          responsibilityName: this.currentResponsibility.name,
          roleCode: "#245",
          roleName: "Implement CAPEX initiatives",
          positionCode: "#4567",
          positionName: "General Manager",
        };
        const exlAppData = JSON.parse(localStorage.getItem("exlAppData"));
        const employees = exlAppData?.employees; // Add optional chaining to avoid errors

        if (Array.isArray(employees)) {
          currentEmployee = employees.find((e) => e.id === emp_id); // Assign found employee
        } else {
          console.error("Employees data is not an array.");
        }
        const badgeList = this.getBadgesFromDataSource(this.dataSource);
        if (currentEmployee) {
          this.evalModalEmpState = {
            id: currentEmployee.id,
            firstName: currentEmployee.first_name,
            lastName: currentEmployee.last_name,
            position: currentEmployee.title,
            photoUrl: currentEmployee.avatar,
            availability: 1,
            isAllocated: false,
            badgeList,
          };
        } else {
          console.error("No employee found with ID:", emp_id);
        }
      } else {
        this.showEmployeeIssueBadge = false;
        this.isInspectResModal = false;
        this.isForceBadge = true;
      }
      this.$loader.setAppLoading(false);
    },
    formatChecklistData(data) {
      if (data && data.data) {
        this.checkpointsData = data.data.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.status = el.id;
          newEl.level = el.proficiency_level;
          newEl.level_name = this.getProficiencyLevelNameFromNumber(el);
          newEl.criteria = el.criteria;
          newEl.instructions = el.instructions;
          newEl.status = el.status;
          newEl.evaluation_status = el.evaluation_status;
          newEl.change_evaluation_status =
            this.updateChangeEvaluationStatus(el);
          newEl.failed_by = el.failed_by;
          newEl.failed_on = el.failed_on;
          newEl.failed_reason = el.evaluation_reason;
          newEl.reset_by = el.reset_by;
          newEl.reset_on = el.reset_on;
          newEl.reset_reason = el.reset_reason;
          newEl.updated_on = el.updated_on;
          newEl.updated_by = el.updated_by;
          newEl.avatar = el.avatar;
          return newEl;
        });
      }
      return this.checkpointsData;
    },
    getProficiencyLevelNameFromNumber(el) {
      let levelName = "";
      if (el.proficiency_level === 1) {
        levelName = "Apprentice";
      } else if (el.proficiency_level === 2) {
        levelName = "Professional";
      } else if (el.proficiency_level === 3) {
        levelName = "Coach";
      } else if (el.proficiency_level === 4) {
        levelName = "Master";
      } else {
        levelName = "Unknown"; // Handle unexpected values
      }
      return levelName;
    },
    updateChangeEvaluationStatus(data) {
      if (data.evaluation_status == "not_applicable") {
        return "Not Applicable";
      } else if (data.evaluation_status == "passed") {
        return "Passed";
      } else if (data.evaluation_status == "failed") {
        return "Failed";
      } else if (data.evaluation_status == "reset") {
        return "Reset Pending Evaluation";
      } else {
        return "Pending Evaluation";
      }
    },
    async onClickForceBadge(item) {
      const responsibilityId = this.currentResponsibility?.id;
      this.loading.forceBadge = true;

      try {
        const emp_id = localStorage.getItem("emp_id");
        const accountID = localStorage.getItem("currentAccountID");
        const payload = {
          employee: emp_id,
          responsibility: responsibilityId,
          level: item.radioVal,
          forced: true,
        };

        await createProficiencyBadge(accountID, payload);

        this.updateResponsibilityBadge(responsibilityId, payload.level);

        this.isForceBadge = false;
        this.showEmployeeIssueBadge = false;
        this.currentSelectRes = null;
        this.currentResponsibility = null;
      } catch (error) {
        console.error("Error issuing badge:", error);
        this.isForceBadge = false;
        this.showEmployeeIssueBadge = false;
        this.currentSelectRes = null;
        this.currentResponsibility = null;
      } finally {
        this.loading.forceBadge = false;
      }
    },
    updateResponsibilityBadge(responsibilityId, badgeLevel) {
      const respIndex = this.dataSource.findIndex(
        (item) =>
          item.data &&
          item.data.type === "responsibility" &&
          item.data.code === this.currentResponsibility?.code_str
      );

      if (respIndex > -1) {
        const badgeInfo = {
          id: Date.now(),
          issued_to: localStorage.getItem("emp_id"),
          is_current: true,
          level: badgeLevel,
        };
        const updatedItem = {
          ...this.dataSource[respIndex],
          data: {
            ...this.dataSource[respIndex].data,
            badge: badgeInfo,
          },
        };
        this.$set(this.dataSource, respIndex, updatedItem);
        if (this.employeeData) {
          this.onEmployeeReadiness();
        }
      }
    },
    getBadgesFromDataSource(dataSource) {
      const badgeList = [];
      dataSource.forEach((item) => {
        if (item.data && item.data.badge) {
          const badge = item.data.badge;
          const newBadge = {
            id: badge.id,
            issued_to: badge.issued_to,
            is_current: badge.is_current,
          };
          badgeList.push(newBadge);
        }
      });
      return badgeList;
    },
    async handleActivateEmployee() {
      this.loading.employeeStatus = true;

      try {
        const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
        await activateEmployee(accountID, this.employeeData?.id);

        if (this.employeeData) {
          this.employeeData = {
            ...this.employeeData,
            current_status: "active",
          };
        }

        this.onCloseModal();

        this.getAuthUser(this.employeeData?.id).catch((error) => {
          console.error("Error updating user data after activation:", error);
        });
      } catch (e) {
        console.error("Error activating employee:", e);
      } finally {
        this.loading.employeeStatus = false;
      }
    },
    async reactivateEmployee() {
      try {
        this.$loader.setAppLoading(true);
        const accountID = JSON.parse(localStorage.getItem("currentAccountID"));

        await reactivateEmployee(accountID, this.employeeData?.id);
        this.onCloseModal();
        this.getAuthUser(this.employeeData?.id);
        this.$loader.setAppLoading(false);
      } catch (e) {
        this.$loader.setAppLoading(false);
        console.log("e: ", e);
      }
    },
    async onAssignPositionToEmployee(item) {
      const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
      this.$loader.setAppLoading(true);
      if (
        !this.employeeData?.id ||
        !item?.id ||
        !this.employeeData?.availability
      ) {
        this.$loader.setAppLoading(false);
        return;
      }
      try {
        const payload = {
          employee: this.employeeData.id,
          position: item.id,
          commitment: this.employeeData.availability,
          allocation: this.employeeData.availability,
        };
        await addPositionAssignment(accountID, payload);
        await this.loadData();
        await this.onEmployeeReadiness();
      } catch (e) {
        console.error("error: ", e);
      } finally {
        this.onCloseModal();
        this.$loader.setAppLoading(false);
      }
    },
  },
};
</script>
<style>
.custom-path-readiness {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.readiness-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #344054;
}
.custom-readiness {
  width: 100%;
  margin-left: 15px;
}
.custom-readiness .progress-bar-container {
  width: 100%;
}
.custom-readiness .progress-value {
  font-weight: 600;
}
.custom-path {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
}
</style>

import { computed } from "vue";

import { useViewState } from "@/views/AsMaster/composables/useViewState";

export function useTableConfig() {
  const viewState = useViewState();

  const tableParams = computed(() => {
    return {
      headers: [
        {
          text: "Mastered Responsibility",
          sortable: true,
          value: "mastered_responsibility",
        },
        {
          text: "Coach too?",
          sortable: true,
          value: "isCoach",
          width: "50px",
        },
        {
          text: "Checklist",
          sortable: true,
          value: "checklist",
          width: "200px",
        },
      ],
      columns: [
        {
          field: "",
          component: "OrgObjectCard",
          props: (item) => ({
            class: "my-4",
            ...viewState.getOrgObjectCardProps(item),
          }),
        },
        {
          field: "",
          component: "SwitchButton",
          props: (item) => ({
            class: "my-4",
            ...viewState.getSwitcherProps(item),
          }),
        },
        {
          field: "",
          component: "ExCheckPointCounter",
          props: (item) => ({
            class: "my-4",
            ...viewState.getCheckPointCounterProps(item),
          }),
        },
      ],
      filters: {
        values: viewState.state.filters.values,
        searchText: viewState.state.filters.searchText,
      },
      hideTopSection: true,
      hideActiveNo: false,
      activeRecords: {
        field: "current_status",
        value: "active",
      },
      actionLabel: "Recalculate All",
      showActionButton: viewState.state.filters.needsRecalculation
        ? false
        : true,
      must: viewState.state.filters.needsRecalculation ? true : false,
      hideGridView: true,
      hideButtonIcon: true,
      title: "Employees Readiness",
      hideButtonInMessage: true,
      hideFilterBtn: true,
      getRowStyle: (item) => ({
        backgroundColor:
          item.position?.data?.status === "active" ? "#ECFDF3" : "#FFFFFF",
      }),
    };
  });
  return {
    tableParams,
  };
}

<template>
  <div class="">
    <div class="user-activity">
      <div class="invited">
        <img src="@components/assets/resend-invite.svg" alt="" />
        <span class="ml-1 invited-text">
          {{ formatDate(last_invited_at) }}
        </span>
      </div>
      <div class="first-invited">
        <img src="@components/assets/clock.svg" alt="" />
        <span class="first-invited-text">
          First invited: {{ formatDate(date_of_invite) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  date_of_invite: {
    type: String,
    default: "",
  },
  last_invited_at: {
    type: String,
    default: "",
  },
});

const formatDate = (isoString) => {
  if (!isoString) return "N/A"; // Handle empty values
  const date = new Date(isoString);
  return date
    .toLocaleString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(" at ", ", "); // Replaces " at " with ", "
};

// Example Usage:
console.log(formatDate("2025-03-26T14:54:00.000Z"));
// Output: "26 March 2025, 14:54"
</script>

<style scoped>
.user-activity {
  display: flex;
  flex-direction: column;
}
.first-invited {
  margin-top: -50px;
}
.first-invited-text {
  color: gray;
  margin-left: 4px;
}
.invited {
  max-width: 250px;
  text-align: end;
}
.invited-text {
  font-size: 15px;
}
</style>

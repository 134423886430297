<template>
  <div style="width: 100%">
    <div class="readiness-container">
      <PageTitle class="page-title" title="As a Master" />
      <EmployeeReadinessMenu :items="menuItems" />
    </div>
    <v-tabs-items v-model="selectedTab" class="custom-tabs">
      <v-tab-item v-for="(item, index) in menuItems" :key="index">
        <v-card
          elevation="0"
          class="employee-readiness-container"
          v-if="selectedTab === 0"
        >
          <AsMasterView :dataSource="dataSource" />
        </v-card>
        <v-card v-if="selectedTab === 2"> </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PageTitle from "@components/common/PageTitle.vue";
import EmployeeReadinessMenu from "@components/EmployeeReadinessMenu/EmployeeReadinessMenu.vue";
import AsMasterView from "./AsMaster/AsMasterView.vue";
import { getEmployeeMasterResponsibilites } from "@/api";
export default {
  components: {
    PageTitle,
    EmployeeReadinessMenu,
    AsMasterView,
  },
  data() {
    return {
      menuItems: [{ title: "Mastered Responsibilities", count: 10 }],
      dataSource: [],
      accountID: null,
      authUser: null,
      topLevel: "position",
      removeBorder: false,
      selectedTab: 0,
      currentReadiness: null,
    };
  },
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.authUser = JSON.parse(localStorage.getItem("user"));
    this.getMasterResponsibilities();
  },
  methods: {
    async getMasterResponsibilities() {
      const empId = this.authUser?.employee?.id;
      const { data } = await getEmployeeMasterResponsibilites(
        this.accountID,
        empId
      );
      if (data.length > 0) {
        this.dataSource = this.transformData(data);
      }
    },
    transformData(dataArray) {
      return dataArray.map((data) => ({
        id: data.id, // Assigning a unique ID based on index
        name: data.name,
        code: this.extractCode(data.code_str),
        isCoach: data.master_will_coach, // Assuming master_will_coach determines coaching role
        checkpoint: {
          apprentice: data.apprentice_checkpoints_count,
          professional: data.professional_checkpoints_count,
          master: data.master_checkpoints_count,
          coach: data.coach_checkpoints_count,
        },
      }));
    },
    extractCode(str) {
      let match = str?.match(/#\d+/);
      return match ? match[0] : null;
    },
  },
};
</script>

<style lang="css" scoped>
.readiness-container {
  padding-left: 15px;
  width: 100%;
}

.page-title {
  margin-top: 16px;
}

.employee-path-to-readiness {
  /* box-shadow: 0px 1px 2px 0px #1018280d; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
}
.employee-readiness-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
  min-width: 125px;
}
.employee-readiness {
  width: 100%;
  margin-left: 15px;
}
.employee-readiness .progress-bar-container {
  width: 100%;
}
.employee-readiness .progress-value {
  font-weight: 600;
}
.employee-path {
  border-radius: 8px;
}
::v-deep .progress-bar-container {
  width: 100%;
}
.employee-readiness-container {
  width: 100%;
  margin-left: 4px;
}

#treelist {
  max-width: 100%;
}

::v-deep(.v-tabs) {
  padding: 13px 0px !important;
  border-bottom: 1px solid #eaecf0 !important;
}

::v-deep(.custom-tabs) {
  top: 20px;
}
</style>

import { computed } from "vue";

import { useViewState } from "./useReadinessChecklistViewState.js";

export function useTableConfig() {
  const viewState = useViewState();

  const tableParams = computed(() => {
    return {
      headers: [
        {
          text: "Criteria to Inspect",
          sortable: true,
          value: "criteria_to_inspect",
        },
        {
          text: "Inspection",
          sortable: true,
          value: "checklist",
          width: "150px",
        },
      ],
      columns: [
        {
          field: "",
          component: "EvaluationInstructionsCard",
          props: (item) => ({
            class: "my-4",
            ...viewState.getEvaluationInstructionCard(item),
          }),
          events: () => ({}),
        },
        {
          field: "",
          component: "CheckpointInspectionStatusBadge",
          props: (item) => ({
            class: "my-4",
            ...viewState.getInspectionStatusBadgeProps(item),
          }),
          events: () => ({}),
        },
      ],
      filters: {
        values: viewState.state.filters.values,
        searchText: viewState.state.filters.searchText,
      },
      showLevelCounter: true,
      levelCounter: {
        apprentice: 10,
        professional: 5,
        coach: 2,
        master: 1,
      },
      hideTopSection: true,
      hideActiveNo: false,
      showActionButton: true,
      hideGridView: true,
      getRowStyle: (item) => ({
        backgroundColor:
          item.position?.data?.status === "active" ? "#ECFDF3" : "#FFFFFF",
      }),
    };
  });
  return {
    tableParams,
  };
}

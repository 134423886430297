<template>
  <div class="ex-checkpoints-status-box">
    <EXTooltip
        placement="bottom"
        :description="tooltipDescription"
        :delay="500"
    >
      <div
          :class="[
          'status-box',
          colorVariant,
          {
            'x-mark': checkpointsValue === null,
            'check-mark': checkpointsValue === 0,
            'number': checkpointsValue && checkpointsValue > 0
          }
        ]"
      >
        <span
            v-if="checkpointsValue === null"
            class="no-checkpoints-text"
        >X</span>
        <span class="d-flex justify-center items-center" v-else-if="checkpointsValue === 0">
          <v-icon
              style="width: 17px"
          >
            $ex-all-checkpoints-passed
          </v-icon>
        </span>
        <span
            v-else-if="checkpointsValue && checkpointsValue > 0"
            class="checkpoints-number-text"
        >{{ checkpointsValue }}</span>
      </div>
    </EXTooltip>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import EXTooltip from "@components/tooltip/EXTooltip.vue";

const props = defineProps({
  checkpointsValue: {
    type: [Number, String, null],
    required: true,
    validator: (value) => {
      if (value === null) return true;
      if (value === 0) return true;
      const numValue = Number(value);
      return !isNaN(numValue) && numValue > 0;
    }
  },
  type: {
    type: String,
    default: 'apprentice',
    validator: (value) => ['apprentice', 'professional', 'coach', 'master'].includes(value)
  }
});

const tooltipDescription = computed(() => {
  if (props.checkpointsValue == null) {
    return "No Active Checkpoints"
  } else if (props.checkpointsValue === 0) {
    return "All Checkpoints passed"
  } else if (props.checkpointsValue && props.checkpointsValue > 0) {
    return "Checkpoints not yet passed"
  }
})

const colorVariant = computed(() => {
  return props.type;
});
</script>

<style scoped>
.ex-checkpoints-status-box {
  height: 24px;
  width: 24px;
}
.no-checkpoints-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
}

.checkpoints-number-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
}

.status-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
  font-size: 18px;
  box-sizing: border-box;
}

.status-box.x-mark {
  color: var(--x-color, #000);
}

.status-box.check-mark {
  color: var(--check-color, #4CAF50);
}

.status-box.number {
  color: var(--number-color, #5D6AF8);
}

.status-box.apprentice {
  border-color: #ff8181;
  background-color: #ffeff0;
}

.status-box.professional {
  border-color: #01ff1b;
  background-color: #f2fffa;
}

.status-box.coach {
  border-color: #00f0ff;
  background-color: #effeff;
}

.status-box.master {
  border-color: #00a3ff;
  background-color: #f2faff;
}
</style>
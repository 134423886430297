import { reactive, getCurrentInstance, computed } from "vue";
import { useFilters } from "@/composables/filters/useFilters";
import { useReadinessEmployees } from "@/composables/readinessEmployees/useReadinessEmployees";

let instance = null;

export function useReadinessEmployeeViewState() {
  if (instance) return instance;
  const filter = useFilters();
  const employees = useReadinessEmployees();
  const app = getCurrentInstance();
  const { proxy } = app;
  const state = reactive({
    filters: {
      values: [],
      searchText: "",
      showFilterDialog: false,
      key: "readinessEmployees",
      clearSelection: "",
      filterGroups: [
        {
          label: "Recalculation",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Requires Recalc", value: "requires_recalc" },
          ],
        },
      ],
      needsRecalculation: false,
    },
    loading: {
      table: false,
    },
    table: {
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
  });

  const applyFilters = (filters) => {
    const newArray = filters
      .map((item) => {
        if (item.value !== null) {
          if (typeof item === "string") {
            return item;
          } else if (
            typeof item === "object" &&
            // eslint-disable-next-line no-prototype-builtins
            item.hasOwnProperty("value")
          ) {
            return item.value;
          }
        }
      })
      .filter(Boolean);
    filter.setFilters({ key: state.filters.key, filters: newArray });
    state.filters.values = newArray;
    state.filters.showFilterDialog = false;
  };

  const removeFilters = (removedFilters) => {
    filter.setFilters({
      key: state.filters.key,
      filters: state.filters.values,
    });
    state.filters.clearSelection = removedFilters;
  };
  const recalculateAllEmployees = async () => {
    proxy.$loader.show();
    await employees.recalculateEmployees();
    proxy.$loader.hide();
  };

  const currentEmployee = computed(
    () =>
      state.assignmentWizard.currentEmployee ||
      state.relieveAssignment.currentEmployee
  );

  const getEmployeeCardProps = (employee) => {
    return {
      firstName: employee.first_name,
      lastName: employee.last_name,
      position: employee.title,
      photoUrl: employee.avatar,
      availability: employee.availability,
      nameUrl: `/organization/employees/${employee.id}/pathtoreadiness`,
      showLinkUnderline: false,
      hideAvailabilitySelector: employee.primary_positions?.length > 0 || false,
      isAllocated: employee.primary_positions?.length > 0 || false,
    };
  };
  const getEmployeeReadinessProps = (employee) => {
    if (employee.needs_recalc) {
      state.filters.needsRecalculation = true;
    }
    return {
      recalc: employee.needs_recalc,
      readiness: employee.current_readiness,
      status: getEmployeeReadinessStatus(employee),
      name: employee.primary_positions[0]?.name,
      code: employee.primary_positions[0]?.code.split(" ")[0] || null,
    };
  };
  const getEmployeeReadinessStatus = (employee) => {
    if (employee.primary_positions.length === 0) {
      return "No Assignments";
    } else if (employee.primary_positions.length > 0) {
      return "Has Assignments";
    }
  };

  // Métodos para filtros
  const updateFilter = (filterName, value) => {
    if (filterName in state.filters) {
      state.filters[filterName] = value;
    }
  };

  const clearFilters = () => {
    state.filters = {
      status: "all",
      department: null,
      position: null,
    };
  };

  // Métodos para loading
  const setLoading = (key, value) => {
    if (key in state.loading) {
      state.loading[key] = value;
    }
  };

  instance = {
    state,
    currentEmployee,
    updateFilter,
    clearFilters,
    setLoading,
    getEmployeeCardProps,
    getEmployeeReadinessProps,
    applyFilters,
    removeFilters,
    recalculateAllEmployees,
  };
  return instance;
}

import { getListofEmployee } from "@/api";
import {
  getEmployees,
  updateEmployees,
} from "@components/helper/localStorageHelper";

export default {
  namespaced: true,
  state: {
    employees: [],
    activeEmployeesCount: 0,
    totalEmployeesCount: 0,
    currentEmployee: {},
    currentEmployeeBadgesCount: 0,
  },
  mutations: {
    setEmployees(state, data) {
      state.employees = data;
      const activeEmployees = data.filter(
        (el) => el.current_status == "active"
      ).length;
      state.activeEmployeesCount = activeEmployees;
    },
    setActiveEmployeesCount(state, data) {
      state.activeEmployeesCount = data;
    },
    setTotalPositionsCount(state, count) {
      state.totalPositionsCount = count;
    },
    setCurrentEmployee(state, data) {
      state.currentEmployee = data;
    },
    setCurrentEmployeeBadges(state, data) {
      state.currentEmployeeBadgesCount = data?.results?.filter(
        (item) => item.is_current === true
      ).length;
    },
  },
  getters: {
    employees: (state) => state.employees,
    activeEmployeesCount: (state) => state.activeEmployeesCount,
    totalEmployeesCount: (state) => state.totalEmployeesCount,
    currentEmployeeReadiness: (state) =>
      state.currentEmployee.current_readiness,
    currentEmployeeStatus: (state) => state.currentEmployee.current_status,
    currentEmployeeTotalBadges: (state) => state.currentEmployeeBadgesCount,
  },
  actions: {
    async loadEmployees({ state, commit }) {
      try {
        let employeeList = getEmployees();
        commit("setEmployees", employeeList);
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        await getListofEmployee(accountId, 1, 500)
          .then((response) => {
            if (response.data && response.data.count) {
              state.totalEmployeesCount = response.data.count;
            }
            if (response.data && response.data.results) {
              const updatedEmployeeList = response.data.results.map(
                (employee) => ({
                  ...employee,
                })
              );
              updateEmployees(updatedEmployeeList);
              commit("setEmployees", updatedEmployeeList);
            }
          })
          .catch((error) => {
            console.error("error fetching employees: ", error);
          });
      } catch (e) {
        console.log("error fetching employees: ", e);
      }
    },
    async loadEmployeesFromServer() {
      try {
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        const { data } = await getListofEmployee(accountId, 1, 500);
        if (data && data.results) {
          return data.results;
        } else {
          return [];
        }
      } catch (e) {
        console.log("error fetching employees from server: ", e);
      }
    },
    setCurrentEmployeeAction({ commit }, employee) {
      commit("setCurrentEmployee", employee);
    },
    currentEmployeeBadges({ commit }, badges) {
      commit("setCurrentEmployeeBadges", badges);
    },
  },
};

import { computed } from "vue";
import { usePositionViewState } from "./usePositionViewState";
import { useDropdownItems } from "@/composables/dropdown/useDropdownItems";

export function useTableConfig() {
  const viewState = usePositionViewState();
  const dropdownItems = useDropdownItems();
  const accountID = JSON.parse(localStorage.getItem("currentAccountID"));

  const tableParams = computed(() => {
    return {
      headers: [
        {
          text: "Position",
          sortable: true,
          value: "name",
        },
        {
          text: "Primary",
          sortable: false,
          value: "primary",
          multiple: true,
          data: [
            {
              text: "Occupied by ",
              img: "employee-organization/primary-assignment-grey.svg",
            },
            // {
            //   text: "Acting As",
            //   img: "employee-organization/theater.svg",
            // },
          ],
        },
        // {
        //   text: "Backed up by",
        //   sortable: false,
        //   value: "backedup",
        //   img: "employee-organization/backedupicon.svg",
        // },
        // {
        //   text: "Preparing",
        //   sortable: false,
        //   value: "prepares",
        //   img: "icons/preparing.svg",
        // },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      columns: [
        {
          field: "",
          component: "PositionCard",
          props: (item) => ({
            class: "my-4 datatable-text-content",
            "data-position-id": item.id,
            count: item.totalAssignment,
            code: viewState.getPositionCode(item),
            name: item.name,
            occupied:
              item?.assigned_employees && item?.assigned_employees.length > 0
                ? "1"
                : "not",
            value: item.capacity,
            clickPopup: true,
            hideCapacitySelector: item.assigned_employees?.length > 0,
          }),
          events: (item) => ({
            input: async (capacity) => {
              await viewState.handleCapacityChange(capacity, item, accountID);
            },
            openPopup: async () => {
              await viewState.openPositionDetails(item);
            },
          }),
        },
        {
          field: "",
          component: "PrimaryPositionOccupationTag",
          props: (item) => ({
            class: "occupied_content my-4",
            ...viewState.getPrimaryPositionOccupationProps(item),
          }),
          events: (item) => ({
            onClose: () => {
              viewState.onRelievePrimaryAssignment(item);
            },
          }),
        },
        {
          field: "",
          component: "StatusChip",
          props: (item) => ({
            text: item.status,
            textColor: viewState.getStatusColor(item.status),
            borderColor: viewState.getStatusColor(item.status),
            backgroundColor: viewState.getStatusColor(item.status),
            dotColor: viewState.getStatusColor(item.status),
            archived: item.archived,
          }),
        },
        {
          field: "",
          component: "Dropdown",
          props: (item) => ({
            "data-testid": "position-operations-menu",
            "translate-x-offset": 12,
            "translate-y-offset": 30,
            "is-open": false,
            "dropdown-items": dropdownItems.getOrgObjectDropdownItems(item),
          }),
          events: (item) => ({
            onClick: async (operation) => {
              await viewState.handlePositionDropdownOperation(operation, item);
            },
          }),
        },
      ],
      filters: {
        values: viewState.state.filters.values,
        searchText: viewState.state.filters.searchText,
      },
      hideTopSection: false,
      hideActiveNo: false,
      activeRecords: {
        field: "status",
        value: "active",
      },
      actionLabel: "Add Position",
      hideGridView: true, //------| add this to hide bulk action in ER-4046 |------
      title: "Positions Organization",
      getRowStyle: (item) => ({
        backgroundColor: item.status === "active" ? "#ECFDF3" : "#FFFFFF",
      }),
    };
  });

  return {
    tableParams,
  };
}

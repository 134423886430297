<template>
  <v-dialog v-model="showDialog" persistent max-width="400px">
    <div class="pop-up">
      <QuadrilleBackground v-if="!emailObject.showMessage" className="background-popup" />
      <img v-if="emailObject.showMessage" :src="require('@components/assets/backgrounds/modal-background.svg')" class="second-bg" alt="" />
      <div class="modal_header">
        <div class="d-flex flex-row">
          <AtTheRateIcon class="ma-5" />
          <v-spacer/>
          <span class="align-self-center ma-4" @click="reset">
          <v-btn
              icon
              color="grey"
          >
            <Icon class="cursor-pointer" name="popup_close"></Icon>
          </v-btn>
        </span>
        </div>
        <h3 class="text-black-600 main-text px-6">Change email to sign in</h3>
      </div>
      <div v-if="!emailObject.showMessage" class="modal_content mt-5 px-6">
        <v-form v-model="isEmailFormValid">
          <p class="text-black-500 mb-2">Email*</p>
          <ExTextInput
            ref="emailInput"
            class="input mb-4"
            required
            placeholder="Enter user email"
            outlined
            color="rgba(208, 213, 221, 1)"
            base-color="rgba(208, 213, 221, 1)"
            name="email"
            v-model="email"
            id="email"
            type="email"
            :hide-details="true"
            @keyup.enter="onEnter"
            autofocus
            :loading="showEmailInputLoadingIcon"
            :rules="emailRules"
            @validation-done="validateEmailDone"
          ></ExTextInput>
        </v-form>
      </div>
      <div v-if="emailObject.showMessage" class="modal_content mt-4 px-6">
        <p class="font-weight-400 font-size-14 text-disabled mt-2">
          {{ message }}
        </p>
      </div>
      <div class="modal_action d-flex justify-end px-6 py-6">
        <ExButton
          ref="backButton"
          v-if="emailObject.showMessage"
          class="mr-2 button-back-email"
          :outline="'outline-hover'"
          :label="'Back'"
          @onClick="goBack"
          icon="arrow-icon.svg"
          size="normal"
          :disabled="showEmailChangeLoadingIcon"
        />
        <ExButton
          ref="nextButton"
          v-if="!emailObject.showMessage || emailObject.validEmail"
          :class="{
            'button-next-change-email': !showEmailChangeLoadingIcon,
          }"
          @onClick="doNext"
          :label="
            email !== '' &&
            email === stepEmailInput &&
            isEmailFormValid &&
            emailObject.validEmail
              ? 'Change'
              : 'Next'
          "
          size="normal"
          :loading="showEmailChangeLoadingIcon ? 'left' : null"
          :disabled="!isEmailFormValid || email === ''"
          :rightIcon="email !== '' &&
            email === stepEmailInput &&
            isEmailFormValid &&
            emailObject.validEmail ? '' : 'right-icon.svg' "
          :normal="'normal-hover'"
        />
      </div>
    </div>
  </v-dialog>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { userCheckEmail , userChangeEmail} from "@/api";
import QuadrilleBackground from "@components/svgs/QuadrilleBackground.vue";
import AtTheRateIcon from "@components/svgs/AtTheRateIcon.vue";
import ExTextInput from "@components/TextInput/ExTextInput.vue";
import ExButton from "@components/buttons/Button.vue";
import Icon from "@components/icons/Icon.vue";
// Props definition
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
  userList: {
    type: Array,
    required: true,
  },
  currentUser: {
    type: Object,
    required: true,
  },
});
// Emits definition
const emit = defineEmits(["closeAddDialog", "emailUpdated"]);
// Refs and reactive state
const email = ref("");
const stepEmailInput = ref("");
const emailObject = reactive({
  access: false,
  employee: false,
  user: false,
  showMessage: false,
  validEmail: false,
});
const existingUser = ref(null);
const showEmailInputLoadingIcon = ref(false);
const showEmailChangeLoadingIcon = ref(false);
const isEmailFormValid = ref(false);
const nextButton = ref(null);
const emailInput = ref(null);
// Computed properties
const emailRules = computed(() => {
  if (email.value.length > 0) {
    return [
      (v) =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ];
  }
  return [];
});
const message = computed(() => {
  if (emailObject.access && emailObject.user && existingUser.value) {
    return `The email ${email.value} is already used by user ${existingUser.value.first_name} ${existingUser.value.last_name} in this account. You cannot use it again.`;
  } else if (!emailObject.access && emailObject.user && existingUser.value) {
    return `The email ${email.value} is already associated with an external user. If you want this external user to be associated with the user ${existingUser.value.first_name} ${existingUser.value.last_name}, please contact exIQtive support.`;
  } else {
    return `Email ${email.value} is available to be used by user ${existingUser.value.first_name} ${existingUser.value.last_name}`;
  }
});
// Methods
const validateEmailDone = (isValid) => {
  isEmailFormValid.value = isValid;
};
const goBack = () => {
  emailObject.access = false;
  emailObject.user = false;
  existingUser.value = null;
  emailObject.showMessage = false;
  emailObject.validEmail = false;
};
const doNext = () => {
  if (email.value === stepEmailInput.value && emailObject.validEmail) {
    changeEmail();
  } else {
    checkuserEmail();
  }
};
const checkuserEmail = async () => {
  showEmailInputLoadingIcon.value = true;
  const accountId = localStorage.getItem("currentAccountID");
  try {
    const response = await userCheckEmail(accountId, {
      email: email.value,
    });
    const data = response.data;
    if (data.user && data.access) {
      emailObject.access = true;
      emailObject.user = true;
      existingUser.value = props.userList.find(
        (x) => x.user.email === email.value
      )?.user;
      emailObject.showMessage = true;
      emailObject.validEmail = false;
    } else if (data.user && !data.access) {
      emailObject.access = false;
      emailObject.user = true;
      existingUser.value = props.userList.find(
        (x) => x.user.email === email.value
      )?.user;
      emailObject.showMessage = true;
      emailObject.validEmail = false;
    } else {
      stepEmailInput.value = email.value;
      emailObject.access = false;
      emailObject.user = false;
      existingUser.value = props.currentUser?.user;
      emailObject.showMessage = true;
      emailObject.validEmail = true;
    }
    showEmailInputLoadingIcon.value = false;
  } catch (err) {
    console.error("error", err);
    showEmailInputLoadingIcon.value = false;
  }
};
const changeEmail = async () => {
  showEmailChangeLoadingIcon.value = true;
  const accountId = localStorage.getItem("currentAccountID");
  const access_id = props?.currentUser.id
  const payload = {
    first_name: props?.currentUser?.user?.first_name,
    last_name: props?.currentUser?.user?.last_name,
    email: email.value,
  }
  try {
    const response = await userChangeEmail(accountId, access_id, payload)
    const data = response.data;
    if(data) {
      emit("emailUpdated");
      emit("closeAddDialog");
    }
  } catch (err) {
    console.error("error", err);
    showEmailChangeLoadingIcon.value = false;
  }
};
const onEnter = () => {
  doNext();
};
const reset = () => {
  email.value = "";
  goBack();
  emit("closeAddDialog", false);
};
// Optional: Lifecycle hook equivalent
onMounted(() => {
  // Disable next button initially if needed
  if (nextButton.value?.$refs?.myButton) {
    nextButton.value.$refs.myButton.disabled = true;
  }
});
</script>
<style scoped>
.text-black-600 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}
.text-black-500 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}
.input {
  border-radius: 8px;
  margin: 0;
}
.pop-up {
  width: 400px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
}
.second-bg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  width: auto;
  height: auto;
}
.modal_action {
  position: relative;
  z-index: 100;
}
.background-popup {
  position: absolute;
  z-index: 0;
}
.main-text{
  margin-top: -5px;
}
.modal_header {
  z-index: 1;
  position: relative;
}
.modal_content {
  z-index: 1;
  position: relative;
}
.font-weight-400 {
  font-weight: 400;
}
.font-size-14 {
  font-size: 14px;
}
.text-disabled {
  color: rgba(102, 112, 133, 1);
}
.v-input--selection-controls {
  padding-top: 0;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin: 0px;
}
.v-input--selection-controls__input .v-input__slot .v-label {
  padding-left: 1px !important;
}
.v-input--selection-controls__input .v-input__slot .theme--light .v-label {
  color: black;
  font-size: 14px;
}
.button-next-change-email:disabled {
  background-color: #E0E0E0 !important;
  color: #FFF !important;
  pointer-events: none;
  cursor: not-allowed;
}
.button-back-email:disabled {
  background-color: #E0E0E0 !important;
  color: #FFF !important;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
<style>
.pop-up .v-input__slot {
  min-height: 44px !important;
}
</style>
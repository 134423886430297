<template>
  <v-container fluid>
    <UniversalTable
      :config="tableConfig.tableParams.value"
      :items="filteredMasteredResponsibilities"
      @onSearch="viewState.state.filters.searchText = $event"
      @onClick="viewState.state.filters.showFilterDialog = true"
      @removeFilter="viewState.removeFilters"
      @removeAllFilters="viewState.state.filters.values = []"
      :emptyObjectData="emptyObjectData"
    >
    </UniversalTable>
    <SearchModal
      :dialog="viewState.state.filters.showFilterDialog"
      @back="viewState.state.filters.showFilterDialog = false"
      @checkedItemsChanged="viewState.applyFilters"
      :clearSelection="viewState.state.filters.clearSelection"
      filterType="Employee Readiness"
      :filterGroups="viewState.state.filters.filterGroups"
      :statusOptions="[]"
      :modalWidth="800"
      :defaultSelected="viewState.state.filters.values"
    />
  </v-container>
</template>

<script setup>
import { computed, defineProps } from "vue";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import { useTableConfig } from "@/views/AsMaster/composables/useTableConfig";
import { useViewState } from "@/views/AsMaster/composables/useViewState";
import SearchModal from "@components/SearchBar/SearchModal.vue";

const tableConfig = useTableConfig();
const viewState = useViewState();
const emptyObjectData = {
  singular: "Mastered Responsibility",
  plural: "Mastered Responsibilities",
};
const props = defineProps({
  dataSource: {
    type: Array,
    required: true,
  },
});

const filteredMasteredResponsibilities = computed(() => {
  let allMasteredResponsibilities = props?.dataSource;

  if (
    viewState.state.filters.searchText &&
    viewState.state.filters.searchText.length > 0
  ) {
    const searchText = viewState.state.filters.searchText.toLowerCase();

    allMasteredResponsibilities = allMasteredResponsibilities.filter((el) => {
      const fullName = `${el.name} ${el.code}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        el.name.toLowerCase().includes(searchText) ||
        el.code.toLowerCase().includes(searchText)
      );
    });
  }

  allMasteredResponsibilities = allMasteredResponsibilities.sort((a, b) => {
    const firstNameA = a?.name?.toLowerCase() || "";
    const firstNameB = b?.name?.toLowerCase() || "";
    const lastNameA = a?.code?.toLowerCase() || "";
    const lastNameB = b?.code?.toLowerCase() || "";

    if (firstNameA < firstNameB) return -1;
    if (firstNameA > firstNameB) return 1;

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;

    return 0;
  });
  return allMasteredResponsibilities;
});
</script>

<style scoped>
.icon-container {
  cursor: pointer;
}
</style>

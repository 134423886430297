import { render, staticRenderFns } from "./UniversalTable.vue?vue&type=template&id=6c09a99f&scoped=true"
import script from "./UniversalTable.vue?vue&type=script&setup=true&lang=js"
export * from "./UniversalTable.vue?vue&type=script&setup=true&lang=js"
import style0 from "./UniversalTable.vue?vue&type=style&index=0&id=6c09a99f&prod&lang=css"
import style1 from "./UniversalTable.vue?vue&type=style&index=1&id=6c09a99f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c09a99f",
  null
  
)

export default component.exports
<template>
  <div>
    <EXTooltip
        placement="bottom"
        :description="tooltipDescription"
        :delay="500"
    >
      <div v-if="badgeLevel == 'novice'">
        <v-icon
        >
          $ex-chevron-right
        </v-icon>
      </div>
      <div v-else :class="['outer-square', badgeLevel]">
        <div class="inner-square">
          <div :class="['circle', badgeStatus]"></div>
        </div>
      </div>
    </EXTooltip>
  </div>
</template>

<script setup>
import EXTooltip from "@components/tooltip/EXTooltip.vue";
import { computed } from "vue";

const props = defineProps({
  badgeLevel: {
    type: String,
    default: 'apprentice',
    validator: (value) => ['novice', 'apprentice', 'professional', 'coach', 'master'].includes(value)
  },
  badgeStatus: {
    type: String,
    default: 'forced',
    validator: (value) => ['forced', 'good_standing', 'obsolete'].includes(value)
  }
});

const tooltipDescription = computed(() => {
  if (props.badgeLevel == "novice") {
    return "Novice"
  } else {
    return `${props.badgeLevel.charAt(0).toUpperCase() + props.badgeLevel.slice(1)} ${formatStatus(props.badgeStatus)}`
  }
});

const formatStatus = (text) => {
  if (text.includes('_')) {
    return text
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  } else {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
</script>

<style scoped>
.container {}

.outer-square {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-square.apprentice {
  background-color: #FF8181;
}

.outer-square.professional {
  background-color: #00FF19;
}

.outer-square.coach {
  background-color: #00F0FF;
}

.outer-square.master {
  background-color: #00A3FF;
}

.inner-square {
  width: 12px;
  height: 12px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.circle.forced {
  background-color: #2C2C2C;
}

.circle.good_standing {
  background-color: #17B26A;
}

.circle.obsolete {
  background-color: #FF0303;
}
</style>

<template>
  <v-container fluid>
    <UniversalTable
      :config="tableConfig.tableParams.value"
      :items="filteredEmployees"
      @onSearch="viewState.state.filters.searchText = $event"
      @onClick="viewState.state.filters.showFilterDialog = true"
      @removeFilter="viewState.removeFilters"
      @removeAllFilters="viewState.state.filters.values = []"
      :emptyObjectData="emptyObjectData"
      @onActionClick="viewState.recalculateAllEmployees"
    >
    </UniversalTable>
    <SearchModal
      :dialog="viewState.state.filters.showFilterDialog"
      @back="viewState.state.filters.showFilterDialog = false"
      @checkedItemsChanged="viewState.applyFilters"
      :clearSelection="viewState.state.filters.clearSelection"
      filterType="Employee Readiness"
      :filterGroups="viewState.state.filters.filterGroups"
      :statusOptions="[]"
      :modalWidth="800"
      :defaultSelected="viewState.state.filters.values"
    />
  </v-container>
</template>

<script setup>
import { onMounted, getCurrentInstance, computed, watch } from "vue";
import { useReadinessEmployees } from "@/composables/readinessEmployees/useReadinessEmployees";
import { usePositions } from "@/composables/positions/usePositions";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import { useTableConfig } from "@/views/ReadinessEmployees/composables/useReadinessEmployeeTableConfig";
import { useReadinessEmployeeViewState } from "@/views/ReadinessEmployees/composables/useReadinessEmployeeViewState";
import SearchModal from "@components/SearchBar/SearchModal.vue";
const app = getCurrentInstance();
const { proxy } = app;

const tableConfig = useTableConfig();
const viewState = useReadinessEmployeeViewState();
const employees = useReadinessEmployees();
const positions = usePositions();
const emptyObjectData = {
  singular: "Readiness Employee",
  plural: "Readiness Employees",
};

const filteredEmployees = computed(() => {
  let allEmployees = employees.allEmployees.value;

  if (
    viewState.state.filters.searchText &&
    viewState.state.filters.searchText.length > 0
  ) {
    const searchText = viewState.state.filters.searchText.toLowerCase();

    allEmployees = allEmployees.filter((el) => {
      const fullName = `${el.first_name} ${el.last_name}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        el.first_name.toLowerCase().includes(searchText) ||
        el.last_name.toLowerCase().includes(searchText) ||
        el.title.toLowerCase().includes(searchText)
      );
    });
  }
  const recalculatiionFilters = ["Requires Recalc"];
  if (
    viewState.state.filters.values.some((val) =>
      recalculatiionFilters.includes(val)
    )
  ) {
    allEmployees = allEmployees.filter((el2) => {
      return viewState.state.filters.values.some((el3) => {
        let val = false;
        if (el3 === "Requires Recalc") {
          val = el2.needs_recalc === true;
        }
        return val;
      });
    });
  }

  allEmployees = allEmployees.sort((a, b) => {
    const firstNameA = a?.first_name?.toLowerCase() || "";
    const firstNameB = b?.first_name?.toLowerCase() || "";
    const lastNameA = a?.last_name?.toLowerCase() || "";
    const lastNameB = b?.last_name?.toLowerCase() || "";

    if (firstNameA < firstNameB) return -1;
    if (firstNameA > firstNameB) return 1;

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;

    return 0;
  });
  if (viewState.state.filters.values.length === 0) {
    allEmployees = allEmployees.filter((el2) => {
      let val = false;
      val = el2;
      return val;
    });
  }
  return allEmployees;
});
// Watcher to Update needsRecalculation Without Side Effects in Computed
watch(
  filteredEmployees,
  (employeesList) => {
    viewState.state.filters.needsRecalculation = employeesList.some(
      (employee) => employee.needs_recalc === true
    );
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  proxy.$loader.show();
  await employees.loadAllEmployees();
  await positions.loadAllPositions();
  proxy.$loader.hide();
});
</script>

<style scoped>
.icon-container {
  cursor: pointer;
}
</style>

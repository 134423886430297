import { usePositionStore } from "@/store/pinia/position";
import { computed } from "vue";
import {
  attachRoleToPosition,
  deActivatePosition,
  reActivatePosition,
  retirePosition,
  removePositionAssignmentForEmployee,
  archivePosition,
  unArchivePosition,
  deletePosition as deletePositionAPI,
  updatePosition as updatePositionAPI,
  createPosition as createPositionAPI,
  activatePosition,
} from "@/api";

export function usePositions() {
  const positionStore = usePositionStore();

  const allPositions = computed(() => positionStore.positions);

  const operations = {
    CREATE_POSITION: "CREATE_POSITION",
    UPDATE_POSITION: "UPDATE_POSITION",
    DELETE_POSITION: "DELETE_POSITION",
    ACTIVATE_POSITION: "ACTIVATE_POSITION",
    DEACTIVATE_POSITION: "DEACTIVATE_POSITION",
    REACTIVATE_POSITION: "REACTIVATE_POSITION",
    RETIRE_POSITION: "RETIRE_POSITION",
    ARCHIVE_POSITION: "ARCHIVE_POSITION",
    RENAME_POSITION: "RENAME_POSITION",
    ATTACH_ROLE: "ATTACH_ROLE",
    RELIEVE_ASSIGNMENT: "RELIEVE_ASSIGNMENT",
    CLONE_POSITION: "CLONE_POSITION",
  };

  const loadAllPositions = async () => {
    await positionStore.loadPositions();
  };

  const createPosition = async ({ accountId, name }) => {
    const payload = {
      name,
      account: accountId,
    };
    const { data } = await createPositionAPI(accountId, payload);
    return data;
  };

  const updatePosition = async ({ accountId, positionId, payload }) => {
    const response = await updatePositionAPI(accountId, positionId, payload);
    return response.data;
  };

  const deletePosition = async ({ accountId, position }) => {
    await deletePositionAPI(accountId, position.id);
  };

  const activate = async ({ accountId, position }) => {
    await activatePosition(accountId, position.id);
  };

  const deactivate = async ({ accountId, position }) => {
    await deActivatePosition(accountId, position.id);
  };

  const reactivate = async ({ accountId, position }) => {
    await reActivatePosition(accountId, position.id);
  };

  const retire = async ({ accountId, position }) => {
    await retirePosition(accountId, position.id);
  };

  const archive = async ({ accountId, position }) => {
    await archivePosition(accountId, "position", position.id);
  };

  const unarchive = async ({ accountId, position }) => {
    await unArchivePosition(accountId, "position", position.id);
  };

  const clonePosition = async ({ accountId, position }) => {
    try {
      const name = position?.name;
      const payload = {
        name: "Clone of " + name,
        account: accountId,
      };
      const { data } = await createPositionAPI(accountId, payload);
      return data;
    } catch (e) {
      console.log("e: ", e);
    }
  };

  const attachRole = async ({ accountId, positionId, roleId }) => {
    const payload = {
      attachment_id: roleId,
    };
    const response = await attachRoleToPosition(accountId, positionId, payload);
    return response.data;
  };

  const relievePrimaryAssignment = async ({ accountId, assignmentId }) => {
    await removePositionAssignmentForEmployee(accountId, assignmentId);
  };

  const rename = async ({ accountId, position, newName }) => {
    const payload = {
      name: newName,
      account: accountId,
    };
    await updatePositionAPI(accountId, position.id, payload);
  };

  const updateCapacity = async ({ accountId, positionId, capacity, name }) => {
    const payload = {
      name,
      account: accountId,
      capacity,
    };
    await updatePositionAPI(accountId, positionId, payload);
  };

  const setCurrentPosition = (position) => {
    positionStore.setCurrentPosition(position);
  };

  const getCurrentPosition = () => {
    return positionStore.getCurrentPosition;
  };

  return {
    allPositions,
    operations,
    loadAllPositions,
    createPosition,
    updatePosition,
    deletePosition,
    activate,
    deactivate,
    reactivate,
    retire,
    archive,
    unarchive,
    clonePosition,
    attachRole,
    relievePrimaryAssignment,
    rename,
    updateCapacity,
    setCurrentPosition,
    getCurrentPosition,
  };
}

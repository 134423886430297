import { computed } from "vue";

import { useReadinessEmployeeViewState } from "@/views/ReadinessEmployees/composables/useReadinessEmployeeViewState";
import { useReadinessEmployees } from "@/composables/readinessEmployees/useReadinessEmployees";

export function useTableConfig() {
  const viewState = useReadinessEmployeeViewState();
  const employee = useReadinessEmployees();
  const accountID = JSON.parse(localStorage.getItem("currentAccountID"));

  const tableParams = computed(() => {
    return {
      headers: [
        {
          text: "Employee",
          sortable: true,
          value: "first_name",
          width: "150px",
        },
        {
          text: "Readiness",
          sortable: true,
          value: "readiness",
        },
      ],
      columns: [
        {
          field: "",
          component: "EmployeeCard",
          props: (item) => ({
            class: "my-4",
            ...viewState.getEmployeeCardProps(item),
          }),
          events: (item) => ({
            "update:availability": async (availabilityValue) => {
              const payload = {
                employee: item,
                value: availabilityValue,
              };
              await employee.onUpdateAvailability({
                accountId: accountID,
                data: payload,
              });
              await employee.loadAllEmployees();
            },
          }),
        },
        {
          field: "",
          component: "EmployeeReadinessBar",
          props: (item) => ({
            class: "my-4",
            ...viewState.getEmployeeReadinessProps(item),
          }),
          events: (item) => ({
            onActionClick: () => {
              employee.getEmployeeReadinessRecal(accountID, item);
            },
          }),
        },
      ],
      filters: {
        values: viewState.state.filters.values,
        searchText: viewState.state.filters.searchText,
      },
      hideTopSection: false,
      hideActiveNo: false,
      activeRecords: {
        field: "current_status",
        value: "active",
      },
      actionLabel: "Recalculate All",
      showActionButton: viewState.state.filters.needsRecalculation
        ? false
        : true,
      must: viewState.state.filters.needsRecalculation ? true : false,
      hideGridView: true,
      hideButtonIcon: true,
      title: "Employees Readiness",
      hideButtonInMessage: true,
      getRowStyle: (item) => ({
        backgroundColor:
          item.position?.data?.status === "active" ? "#ECFDF3" : "#FFFFFF",
      }),
    };
  });
  return {
    tableParams,
  };
}

<template>
  <v-container fluid class="emp-assignments-view">
    <PageTitle title="Employee Positions History" />
    <!-- commented for ER-3838 -->
    <!-- <div v-if="showNotification">
      <RemovableBannerComponent
        first_text_line="We’ve just announced our Series A!"
        second_text_line="Read about it from our CEO."
        :lines="1"
        banner_type="white"
      />
    </div> -->
    <div style="">
      <NoRecordFound
        v-if="emoloyeeAssignment.length === 0"
        :title="''"
        :subtitle="''"
        message="Assignment"
        class="no-record-found"
        :shouldShowAddAction="false"
        :noBorder="false"
        :showActiveCounter="false"
      />
      <EmployeeAssignments v-else :dataSource="emoloyeeAssignment" />
    </div>
  </v-container>
</template>

<script>
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import EmployeeAssignments from "@components/EmployeeAssignments/EmployeeAssignments.vue";
import PageTitle from "@components/common/PageTitle.vue";
// import RemovableBannerComponent from "@components/RemovableBanner/RemovableBannerComponent.vue";
import { getEmployeeAssignment } from "@/api";
export default {
  name: "EmployeeAssignmentView",
  components: {
    NoRecordFound,
    EmployeeAssignments,
    PageTitle,
    // RemovableBannerComponent,
  },
  data() {
    return {
      showNotification: true,
      dataList: [],
      emoloyeeAssignment: [],
    };
  },
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    await this.getEmployeesAssignments();
  },
  methods: {
    async getEmployeesAssignments() {
      const empId = this.$route.params.id;
      const val = false;
      this.$loader.show();
      const { data } = await getEmployeeAssignment(this.accountID, empId, val);
      if (data.length > 0) {
        this.emoloyeeAssignment = this.transformData(data);
      }
      this.$loader.hide();
    },
    transformData(dataArray) {
      const result = [];
      const today = new Date();

      dataArray.forEach((data) => {
        const isNew =
          new Date(data.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
          today.getTime();

        const mainObject = {
          id: data.id, // Use API ID
          parentId: -1, // Root-level has no parent
          data: {
            type: data.assignment_type,
            commitmentLevel: data.commitment_level,
            assignmentType: data.assignment_type,
            importanceLevel: data.importance,
            importanceSelector: true,
            totalSteps: 3,
            code: data.position_code,
            name: data.position_name,
            assignedOn: data.assigned_on,
            removedOn: data.removed_on,
            assignedBy: data.assigned_by,
            removedBy: data.removed_by,
            reason: data.reason,
            isNew: isNew,
            isAccepted: false,
          },
          status: data.status,
          readiness: data.current_readiness,
        };
        result.push(mainObject);

        if (data.role_assignments) {
          data.role_assignments.forEach((role) => {
            const isRoleNew =
              new Date(role.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
              today.getTime();

            const roleObject = {
              id: `role-${role.id}`, // Ensure uniqueness with a prefix
              parentId: data.id, // Set parent ID to main object
              data: {
                type: role.assignment_type,
                commitmentLevel: role.commitment_level,
                assignmentType: role.assignment_type,
                importanceLevel: role.importance,
                importanceSelector: true,
                totalSteps: 3,
                code: role.role_code,
                name: role.role_name,
                assignedOn: role.assigned_on,
                removedOn: role.removed_on,
                assignedBy: role.assigned_by,
                removedBy: role.removed_by,
                reason: role.reason,
                isNew: isRoleNew,
                isAccepted: isRoleNew,
                acceptanceStatus: "pending",
              },
              status: role.status,
              readiness: role.current_readiness,
            };
            result.push(roleObject);

            if (role.responsibility_assignments) {
              role.responsibility_assignments.forEach((resp) => {
                const isRespNew =
                  new Date(resp.assigned_on).getTime() +
                    10 * 24 * 60 * 60 * 1000 >
                  today.getTime();
                const responsibilityObject = {
                  id: `resp-${resp.id}`, // Ensure uniqueness
                  parentId: `role-${role.id}`, // Correctly set parent ID
                  data: {
                    type: resp.assignment_type,
                    commitmentLevel: resp.commitment_level,
                    assignmentType: resp.assignment_type,
                    importanceLevel: resp.importance,
                    importanceSelector: true,
                    totalSteps: 3,
                    code: resp.responsibility_code,
                    name: resp.responsibility_name,
                    assignedOn: resp.assigned_on,
                    removedOn: resp.removed_on,
                    assignedBy: resp.assigned_by,
                    removedBy: resp.removed_by,
                    reason: resp.reason,
                    isNew: isRespNew,
                    isAccepted: isRespNew,
                    acceptanceStatus: "pending",
                  },
                  status: resp.status,
                  readiness: resp.current_readiness,
                };
                result.push(responsibilityObject);
              });
            }
          });
        }
      });

      return result;
    },

    hideNotification() {
      this.showNotification = false;
    },
  },
};
</script>

<style scoped>
.emp-assignments-view .main-heading {
  font-family: Inter;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-align: left;
  color: #101828;
  padding-bottom: 20px;
}
.emp-assignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #eaecf0;
  border-radius: 10px;
  margin-bottom: 25px;
}
.emp-assignments-view .list-section {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 24px;
}
.emp-assignments-view .notifi-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.emp-assignments-view .notification-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  color: #344054;
  text-align: left;
}
.emp-assignments-view .notification-description {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #475467;
  text-align: left;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ex-checkpoints-status-box"},[_c(_setup.EXTooltip,{attrs:{"placement":"bottom","description":_setup.tooltipDescription,"delay":500}},[_c('div',{class:[
        'status-box',
        _setup.colorVariant,
        {
          'x-mark': _vm.checkpointsValue === null,
          'check-mark': _vm.checkpointsValue === 0,
          'number': _vm.checkpointsValue && _vm.checkpointsValue > 0
        }
      ]},[(_vm.checkpointsValue === null)?_c('span',{staticClass:"no-checkpoints-text"},[_vm._v("X")]):(_vm.checkpointsValue === 0)?_c('span',{staticClass:"d-flex justify-center items-center"},[_c('v-icon',{staticStyle:{"width":"17px"}},[_vm._v(" $ex-all-checkpoints-passed ")])],1):(_vm.checkpointsValue && _vm.checkpointsValue > 0)?_c('span',{staticClass:"checkpoints-number-text"},[_vm._v(_vm._s(_vm.checkpointsValue))]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
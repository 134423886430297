<template>
  <v-container fluid>
    <UniversalTable
      :config="tableConfig.tableParams.value"
      :items="filteredEmployees"
      @onActionClick="
        employees.setCurrentEmployee(null);
        viewState.state.addEmployeeModal.show = true;
      "
      @onSearch="viewState.state.filters.searchText = $event"
      @onClick="viewState.state.filters.showFilterDialog = true"
      @removeFilter="viewState.removeFilters"
      @removeAllFilters="viewState.state.filters.values = []"
    >
      <template #Dropdown-activator>
        <div class="icon-container">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-svg"
          >
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </template>
    </UniversalTable>
    <AddEmployeeModal
      :show="viewState.state.addEmployeeModal.show"
      @close="viewState.state.addEmployeeModal.show = false"
      @onSave="
        viewState.handleEmployeeOperation(
          employees.operations.EDIT_EMPLOYEE,
          accountID,
          $event
        )
      "
      :propEmployee="
        employees.getCurrentEmployee()
          ? {
              id: employees.getCurrentEmployee()?.id || 0,
              first_name: employees.getCurrentEmployee()?.first_name || '',
              last_name: employees.getCurrentEmployee()?.last_name || '',
              title: employees.getCurrentEmployee()?.title || '',
              availability: employees.getCurrentEmployee()?.availability || 0,
              avatar: employees.getCurrentEmployee()?.avatar || '',
            }
          : null
      "
    ></AddEmployeeModal>
    <ExEmployeePrimaryAssignmentWizard
      :label="'Position'"
      :items="employees.eligiblePositions.value"
      :currentEmployee="employees.getCurrentEmployee()"
      :value="viewState.state.assignmentWizard.show"
      @input="!$event ? viewState.closeAssignmentWizard() : ''"
      @onAssignPosition="
        viewState.onAssignPositionToEmployee({
          position: $event,
          accountId: accountID,
        })
      "
    />
    <SearchModal
      :dialog="viewState.state.filters.showFilterDialog"
      @back="viewState.state.filters.showFilterDialog = false"
      @checkedItemsChanged="viewState.applyFilters"
      :clearSelection="viewState.state.filters.clearSelection"
      filterType="Employee"
      :filterGroups="viewState.state.filters.filterGroups"
      :statusOptions="viewState.state.filters.statusOptions"
      :modalWidth="800"
      :defaultSelected="viewState.state.filters.values"
    />
    <RelievePrimaryAssignment
      v-if="viewState.state.relieveAssignment.show"
      :show="viewState.state.relieveAssignment.show"
      :avatar="{
        picture: employees.getCurrentEmployee()?.avatar || '',
        firstName: employees.getCurrentEmployee()?.first_name || '',
        lastName: employees.getCurrentEmployee()?.last_name || '',
      }"
      :current-position="viewState.state.relieveAssignment.currentPosition.name"
      @onAction="viewState.relievePrimaryPositionFromEmployee(accountID)"
      @onClose="viewState.closeRelieveAssignment()"
    />
    <TerminateEmployee
      @onAction="
        viewState.handleEmployeeOperation(
          employees.operations.TERMINATE_EMPLOYEE,
          accountID
        )
      "
      @onClose="viewState.state.terminateEmployeeModal.show = false"
      :show="viewState.state.terminateEmployeeModal.show"
      :info="viewState.state.terminateEmployeeModal.message"
      :avatar="{
        picture: '',
        firstName: `${employees.getCurrentEmployee()?.first_name || 'U'}`,
        lastName: `${employees.getCurrentEmployee()?.last_name || 'S'}`,
      }"
    />
    <DeleteEmployee
      @onAction="
        viewState.handleEmployeeOperation(
          employees.operations.DELETE_EMPLOYEE,
          accountID
        )
      "
      @onClose="viewState.state.deleteEmployeeModal.show = false"
      :show="viewState.state.deleteEmployeeModal.show"
      :info="viewState.state.deleteEmployeeModal.message"
      :avatar="{
        picture: '',
        firstName: `${employees.getCurrentEmployee()?.first_name || 'U'}`,
        lastName: `${employees.getCurrentEmployee()?.last_name || 'S'}`,
      }"
    />
    <ActivateEmployee
      :show="viewState.state.activeEmployeeModal.show"
      :employee="employees.getCurrentEmployee()"
      @onClose="viewState.state.activeEmployeeModal.show = false"
      @onActiveEmployee="
        viewState.handleEmployeeOperation(
          employees.operations.ACTIVATE_EMPLOYEE,
          accountID
        )
      "
    ></ActivateEmployee>
    <ReActivateEmployee
      :show="viewState.state.reactivateEmployeeModal.show"
      :employee="{
        firstName: employees.getCurrentEmployee()?.first_name || '',
        lastName: employees.getCurrentEmployee()?.last_name || '',
        ...employees.getCurrentEmployee(),
      }"
      @onClose="viewState.state.reactivateEmployeeModal.show = false"
      @onActiveEmployee="
        viewState.handleEmployeeOperation(
          employees.operations.REACTIVATE_EMPLOYEE,
          accountID
        )
      "
    ></ReActivateEmployee>
    <EditModalPicture
      :dialog="viewState.state.changePictureModal.show"
      :avatar="employees.getCurrentEmployee()?.avatar || ''"
      @croppedImage="
        viewState.handleEmployeeOperation(
          employees.operations.CHANGE_EMPLOYEE_PICTURE,
          accountID,
          $event
        )
      "
      @closeModal="viewState.state.changePictureModal.show = false"
      @removeModal="
        viewState.handleEmployeeOperation(
          employees.operations.CHANGE_EMPLOYEE_PICTURE,
          accountID,
          null
        )
      "
    />
    <GiveAccessToEmployeeModal
      @checkEmail="
        viewState.checkUserEmail({ accountId: accountID, email: $event })
      "
      @onSave="
        viewState.giveAccessToEmployee({
          accountId: accountID,
          payload: $event,
        })
      "
      @onChangeContentType="viewState.state.giveAccessModal.dataType = $event"
      v-model="viewState.state.giveAccessModal.show"
      :contentTypeProp="viewState.state.giveAccessModal.dataType"
      :existingUser="viewState.state.giveAccessModal.existingUser"
      :current-employee="{
        id: employees.getCurrentEmployee()?.id || 0,
        firstName: employees.getCurrentEmployee()?.first_name || '',
        lastName: employees.getCurrentEmployee()?.last_name || '',
        availabilityVal: employees.getCurrentEmployee()?.availability,
        avatar: employees.getCurrentEmployee()?.avatar || '',
      }"
    />
    <ArchivePopUp
      :action="viewState.state.archiveAction"
      type="employee"
      :show="viewState.state.archiveEmployeeModal.show"
      :curretObj="{
        firstName: employees.getCurrentEmployee()?.first_name || '',
        lastName: employees.getCurrentEmployee()?.last_name || '',
      }"
      @onClose="viewState.state.archiveEmployeeModal.show = false"
      @onArchive="
        viewState.handleEmployeeOperation(
          employees.operations.ARCHIVE_EMPLOYEE,
          accountID
        )
      "
    ></ArchivePopUp>
  </v-container>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, computed } from "vue";
import { useEmployees } from "@/composables/employees/useEmployees";
import { usePositions } from "@/composables/positions/usePositions";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import ExEmployeePrimaryAssignmentWizard from "@components/PositionPicker/ExEmployeePrimaryAssignmentWizard.vue";
import RelievePrimaryAssignment from "@components/organizations/employees/RelievePrimaryAssignment.vue";
import { useTableConfig } from "@/views/Employees/composables/useTableConfig";
import { useEmployeeViewState } from "@/views/Employees/composables/useEmployeeViewState";
import ReActivateEmployee from "@components/modals/ReActivateEmployee.vue";
import TerminateEmployee from "@components/organizations/employees/TerminateEmployee.vue";
import DeleteEmployee from "@components/organizations/employees/DeleteEmployee.vue";
import ActivateEmployee from "@components/modals/ActiveEmployee.vue";
import AddEmployeeModal from "@components/organizations/employees/AddEmployeeModal.vue";
import EditModalPicture from "@components/EditPicture/EditModal.vue";
import GiveAccessToEmployeeModal from "@components/GiveAccessEmployee/GiveAccessToEmployeeModal.vue";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import ArchivePopUp from "@components/common/ArchivePopUp.vue";
const app = getCurrentInstance();
const { proxy } = app;

const tableConfig = useTableConfig();
const viewState = useEmployeeViewState();
const employees = useEmployees();
const positions = usePositions();

const accountID = ref(JSON.parse(localStorage.getItem("currentAccountID")));
const filteredEmployees = computed(() => {
  let allEmployees = employees.allEmployees.value;

  // Apply search filter
  if (viewState.state.filters.searchText?.trim()) {
    const searchText = viewState.state.filters.searchText.toLowerCase();
    allEmployees = allEmployees.filter((employee) => {
      const fullName =
        `${employee.first_name} ${employee.last_name}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        employee.title.toLowerCase().includes(searchText)
      );
    });
  }

  // Filter by status
  if (
    viewState.state.filters.values.some((val) =>
      viewState.state.filters.statusOptions.includes(val)
    )
  ) {
    if (viewState.state.filters.values.includes("Show")) {
      allEmployees = allEmployees.filter((employee) =>
        viewState.state.filters.values.includes(employee.current_status)
      );
    } else {
      allEmployees = allEmployees.filter(
        (employee) =>
          viewState.state.filters.values.includes(employee.current_status) &&
          !employee.archived
      );
    }
  }

  // Filter by availability
  const availabilityFilters = ["Availability 0.5 FTE", "Availability 1 FTE"];
  if (
    viewState.state.filters.values.some((val) =>
      availabilityFilters.includes(val)
    )
  ) {
    allEmployees = allEmployees.filter((employee) => {
      return viewState.state.filters.values.some((filter) => {
        if (filter === "Availability 0.5 FTE") {
          return employee.availability === 0.5;
        } else if (filter === "Availability 1 FTE") {
          return employee.availability === 1;
        }
        return false;
      });
    });
  }

  // Filter by archived status
  const archivedFilters = ["Show", "Do not Show"];
  if (
    viewState.state.filters.values.some((val) => archivedFilters.includes(val))
  ) {
    allEmployees = allEmployees.filter((employee) => {
      return viewState.state.filters.values.some((filter) => {
        if (filter === "Show") {
          return true; // Include all archived and non-archived employees
        } else if (filter === "Do not Show") {
          return !employee.archived; // Exclude archived employees
        }
        return false;
      });
    });
  }

  // Default filter when no filters are applied
  if (viewState.state.filters.values.length === 0) {
    allEmployees = allEmployees.filter((employee) => !employee.archived);
  }

  // Sort employees by first name, then last name
  return allEmployees.sort((a, b) => {
    const firstNameA = a?.first_name?.toLowerCase() || "";
    const firstNameB = b?.first_name?.toLowerCase() || "";
    const lastNameA = a?.last_name?.toLowerCase() || "";
    const lastNameB = b?.last_name?.toLowerCase() || "";

    if (firstNameA < firstNameB) return -1;
    if (firstNameA > firstNameB) return 1;

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;

    return 0;
  });
});

onMounted(async () => {
  proxy.$loader.show();
  await employees.loadAllEmployees();
  await positions.loadAllPositions();
  proxy.$loader.hide();
  // Rest filters on navigation from another components
  if (
    sessionStorage.getItem("navigated") !== null &&
    sessionStorage.getItem("navigated") == "Login"
  ) {
    viewState.state.filters.values = [];
  }
});
</script>

<style scoped>
.icon-container {
  cursor: pointer;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ExGenericSelector',{attrs:{"tipSide":"left","variant":"border","width":288,"persistent":"","leftTransition":true,"translateXOffset":12,"translateYOffset":16},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"employee_availability_icon",class:{no_padding: !_vm.isAllocated && _vm.value === 1}},[_c('div',{staticClass:"circle",class:{
      bg_white_image: !_vm.isAllocated && _vm.value === 1,
      bg_gray: _vm.isAllocated && _vm.value === 1,
      bg_black_white: !_vm.isAllocated && _vm.value === 0.5,
      bg_black_gray: _vm.isAllocated && _vm.value === 0.5,
      bg_gray_image: !_vm.isAllocated && _vm.value === 0,
    }})])]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{staticClass:"employee_availability_icon_container",attrs:{"flat":""}},[_c('div',{staticClass:"employee_availability_icon_content"},[_c('div',{staticClass:"selector"},[_c('div',{staticClass:"content d-flex flex-column align-start pa-3 pb-4"},[_c('div',{staticClass:"text_fte d-flex justify-start text_container"},[_c('span',{staticClass:"text_fte mb-2"},[_vm._v("Availability")]),_c('v-btn',{staticClass:"avail-close-btn",attrs:{"icon":"","color":"#98A2B3"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-radio-group',{attrs:{"hide-details":"","data-name":"radiogroup"},model:{value:(_vm.fteValue),callback:function ($$v) {_vm.fteValue=$$v},expression:"fteValue"}},[_c('v-radio',{staticClass:"mb-4",attrs:{"color":"#7f56d9","data-name":"fte1radio","value":1},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('div',{staticClass:"fte_1 me-5"}),_c('div',{staticClass:"text_fte"},[_vm._v("1 FTE")])])]},proxy:true}])}),_c('v-radio',{staticClass:"mb-4",attrs:{"color":"#7f56d9","data-name":"fte05radio","value":0.5},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('div',{staticClass:"fte_05 me-5"},[_c('div',{staticClass:"fte_circle"})]),_c('div',{staticClass:"text_fte"},[_vm._v("0.5 FTE")])])]},proxy:true}])}),_c('v-radio',{attrs:{"color":"#7f56d9","data-name":"fte0radio","value":0},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('div',{staticClass:"fte_0 me-5"},[_c('div',{staticClass:"fte_circle"})]),_c('div',{staticClass:"text_fte"},[_vm._v("0 FTE - External Help")])])]},proxy:true}])})],1)],1)])])])]},proxy:true}]),model:{value:(_vm.showSelector),callback:function ($$v) {_vm.showSelector=$$v},expression:"showSelector"}})
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('OrgObjectTreeList',{attrs:{"store-persist-filters":true,"store-filters-key-prefix":'readinessCatalogsRoleAttachment',"topLevel":"responsibility","dropdownItems":_vm.dropdownItemsFunc,"actionButtonIcon":"icons/attach_white.svg","removeBorder":true,"optionalColumns":[
        {
          name: "dashboard",
          caption: "Dashboard"
        },
        {
          name: "assignment",
          caption: "Assignment"
        },
        {
          name: "attachedto",
          caption: "Attached to"
        },
    ],"dataSource":_vm.paginatedData,"pagination":_vm.pagination,"allDataSource":_vm.tableData,"btnText":'Attach',"dLoader":_vm.loader},on:{"onRequest":_vm.changePaginationData,"onSearchMode":function($event){return _vm.$emit('onSearchMode', $event)},"onButtonAction":function($event){return _vm.$emit('onButtonAction')},"detach":function($event){return _vm.$emit('detach', $event)},"optionClick":function($event){return _vm.handleDropdownClick($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }